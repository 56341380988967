import React, { useEffect, useState, useRef } from 'react';
import useFetchWithParams from "../../services/useFetchWithParams.js"
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid"
import CircularProgress from '@mui/material/CircularProgress';
import InvoiceButton from './InvoiceButton.jsx';
import InvoiceDetails from '../InvoiceHistory/InvoiceDetails.jsx';
import { Dialog, DialogContent } from '@mui/material';
import SnackbarEx from '../Helpers/SnackbarEx.jsx';
import ManualEntry from './ManualEntry.jsx';
import AddIngredient from './AddIngredient.jsx';
import Popover from '@mui/material/Popover';
import AddPDFFile from './HandleUploadInvoice/AddPDFFile.jsx';
import { useNavigate } from 'react-router-dom';
import { currencyFormatter } from '../Helpers/CurrencyFormat.js';
import PostData from '../../services/PostData.js';
import NewIngredientParInfo from '../Menu/HelperDialogs/NewIngredientParInfo.jsx';
import EditRawIngredientInfo from './EditRawIngredientInfo.jsx';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from '../Menu/HelperDialogs/ConfirmDelete.jsx';
import DeleteData from '../../services/DeleteData.js';
import UploadedNewIngredient from '../Menu/HelperDialogs/UploadedNewIngredient.jsx';
import InformNewItemRecipe from '../Menu/posMenuTab/posMenuSubComponents/InformNewItemRecipe.jsx';
import SettingsIcon from '@mui/icons-material/Settings';
import CategorySettings from './CategorySettings.jsx';
import HoverOverTootlTip from '../Helpers/HoverOverToolTip.jsx';
import ProductCsv from './UploadNewItems/ProductCsv.jsx'

const apiUrl = `api/ingredients`
export default function FoodBev({ reload, pageTab }) {
    useEffect(() => {
        setReloadData(true)
    }, [pageTab, reload])

    const [load, setLoad] = useState(true)
    const [reloadData, setReloadData] = useState(false)

    let { data, error } = useFetchWithParams(apiUrl, { "inventoryLocation": pageTab }, reloadData)

    useEffect(() => {
        setLoad(false)
        setData()
        setReloadData(false)
    }, [data])


    const [row, setRow] = useState([])
    const [col, setCol] = useState([])

    const navigate = useNavigate()
    const [openRecipeInform, setOpenRecipeInform] = useState(false)
    const [editUploadedPDF, setEditUploadedPDF] = useState(false)
    const [openRepeatInvoice, setOpenRepeatInvoice] = useState(false)
    const [openCategorySettings, setOpenCategorySettings] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [openProdUpl, setOpenProdUpl] = useState(false)
    const [addManInvoice, setAddManInvoice] = useState(false)
    const [autoAddInvoice, setAutoAddInvoice] = useState(null)
    const [addIngredient, setAddingredient] = useState(false)
    const [openNewIngredientInfo, setOpenNewIngredientInfo] = useState(false)
    const [openNewUploadIngredientInfo, setOpenNewUploadIngredientInfo] = useState(false)
    const [openInvoiceDetails, setOpenInvoiceDetails] = useState({
        "openDialog": false,
        "invNum": null,
        "invVendor": null
    })
    const [newIngredients, setNewIngredients] = useState([null])
    const [enableEdit, setEnableEdit] = useState(false)
    const [selIngrIdArr, setSelIngrIdArr] = useState(null)

    const [editIngredient, setEditIngredient] = useState({
        editEnabled: false,
        ingredientId: null,
        vendorId: null
    })

    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const [hoverAnchorEl, setHoverAnchorEl] = useState(null);
    const openHoverCal = Boolean(hoverAnchorEl);

    const [editFieldVal, setEditFieldVal] = useState(
        {
            header: "",
            ingredientNm: "",
            storeName: null,
            ingredientId: null,
            parVal: null,
            stockVal: null,
            packagingName: null
        }
    )

    const previousColValues = useRef(editFieldVal)

    // Create rules for the Chip colors
    const CustomCellRenderer = ({ value, rowVal }) => {

        const ingNm = rowVal

        let numericValue = parseInt(value.match(/-?\d+/)[0], 10)
        let fin = data.find(ele => ele.ingredientId === ingNm)
        let par = fin.parThreshold
        const warn = par * 1.05

        let cellColor = '#c90303'
        let backGroundColor = "#ffefee"
        // let icon = <ErrorIcon color='error' sx={{ paddingRight: '5px' }} />
        if (numericValue > warn) {
            cellColor = 'darkgreen';
            backGroundColor = "#c0fccf";
            // icon = <CheckCircleIcon color='success' sx={{ paddingRight: '5px' }} />
        } else if (numericValue > par) {
            cellColor = '#b36200';
            backGroundColor = "#fff2e0"
            // icon = <WarningIcon color='warning' sx={{ paddingRight: '5px' }} />
        }
        const cellStyle = {
            color: cellColor,
            display: 'flex',
            // border: `solid .2px ${cellColor}`,
            backgroundColor: backGroundColor,
            alignItems: 'center',
            padding: '1px 10px',
            fontWeight: '600',
            width: '115px',
            borderRadius: '5px',

        };

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <div style={{ marginLeft: '0px' }}>{icon}</div> */}
                <div style={cellStyle} >
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                        onMouseEnter={(event) => {
                            setHoverAnchorEl(event.currentTarget)
                        }}
                        onMouseLeave={() => setHoverAnchorEl(false)} >{value}</div>
                </div>
            </div>
        )

    };
    const CustomParRender = ({ value }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', color: "#3f3f3f", fontSize: "13px", fontWeight: "600" }}>
                <div onMouseEnter={(event) => {
                    setHoverAnchorEl(event.currentTarget)
                }}
                    onMouseLeave={() => setHoverAnchorEl(false)} >{value}</div>
            </div>
        )
    }
    {/*------------------------------------------------------------ ON CLICKING THE CATEGORY SETTINGS ----------------------------------------------------- */ }
    const handleHeaderClick = () => {
        setOpenCategorySettings(true)
        // Add logic to open a settings dialog or toggle a menu
    };

    {/*------------------------------------------------------------ SET TABLE DATA ----------------------------------------------------- */ }
    const setData = () => {
        const mapData = data ? data : []
        setRow(mapData.map((e) =>
        ({
            id: e.ingredientId,
            remainingStock: e.parStock > 1
                ? `${e.parStock?.toFixed(2)} ${e.parPackaging ? e.parPackaging.toLowerCase() + 's' : 'units'}`
                : `${e.parStock?.toFixed(2)} ${e.parPackaging ? e.parPackaging.toLowerCase() : 'unit'}`,

            storeName: e.storeName ?? "Add item name.",
            col1: e.ingredient,
            category: e.category,
            col2: e.vendor,
            col3: e.parStock,
            col4: e.totalPurchasePrice,
            parUnitPrice: e.parPricePerUnit,
            col5: e.pricePerUnit === 0 ? 0 : e.pricePerUnit,
            col6: e.lastPurchased,
            col7: e.parThreshold,
        })
        ))

        setCol([
            {
                field: 'remainingStock',
                headerName: 'Stock Remaining',
                minWidth: 135,
                // maxWidth: 135,
                editable: true,
                renderCell: (params) => <CustomCellRenderer value={params.value} rowVal={params.id} />
            },
            {
                field: 'storeName',
                headerName: 'Store Item Name',
                minWidth: 145,
                // maxWidth: 175,
                editable: true,
                renderCell: (params) => (
                    <div className="grid-cell-wrap"
                        style={{
                            whiteSpace: "normal", wordWrap: "break-word",
                            overflow: "hidden", textOverflow: "ellipsis",
                            lineHeight: "1.2em", maxHeight: "2.4em"
                        }}>
                        <CustomParRender value={params.value} />
                    </div>)
            },
            {
                field: 'col1',
                headerName: 'Ingredient',
                minWidth: 225,
                editable: true,
                renderCell: (params) => (
                    <div className="grid-cell-wrap"
                        style={{
                            whiteSpace: "normal", wordWrap: "break-word",
                            overflow: "hidden", textOverflow: "ellipsis",
                            lineHeight: "1.2em", maxHeight: "2.4em"
                        }}>
                        <CustomParRender value={params.value} />
                    </div>
                )
                // renderCell: (params) => <CustomParRender value={params.value} />
            },
            {
                field: 'category', headerName: 'Category', minWidth: 160, renderHeader: (params) => (
                    <HoverOverTootlTip message={"Edit Category names"} placement={'top'}>
                        <div id='cat-head'
                            onClick={() => handleHeaderClick('name')}>
                            <span style={{ fontWeight: '600' }}>{params.colDef.headerName}</span>
                            <SettingsIcon fontSize={'small'} style={{ marginLeft: 5, color: 'gray' }} />
                        </div>
                    </HoverOverTootlTip>
                ),
            },  // I want this to be able to select a settings icon to change the category names
            {
                field: 'col2', headerName: 'Vendor', minWidth: 125, maxWidth: 160, renderCell: (params) => (
                    <div className="grid-cell-wrap"
                        style={{
                            whiteSpace: "normal", wordWrap: "break-word",
                            overflow: "hidden", textOverflow: "ellipsis",
                            lineHeight: "1.2em", maxHeight: "2.4em"
                        }}>
                        <CustomParRender value={params.value} />
                    </div>)
            },  // Add resizable
            {
                field: 'col7',
                headerName: 'Reorder At',
                minWidth: 65,
                // maxWidth: 65,
                editable: true,
                renderCell: (params) => <CustomParRender value={params.value} />
            },
            {
                field: 'col4',
                headerName: 'Lifetime Cost',
                minWidth: 125,
                // maxWidth: 110,                
                valueFormatter: ({ value }) => currencyFormatter(value, 2)
            },
            {
                field: 'parUnitPrice',
                headerName: 'Raw Ingredient Cost',
                minWidth: 105,
                // maxWidth: 105, 
                valueFormatter: ({ value }) => currencyFormatter(value, 2)
            },
            {
                field: 'col5',
                headerName: 'Recipe Unit Cost',
                minWidth: 95,
                // maxWidth: 95,                
                valueFormatter: ({ value }) => currencyFormatter(value, 3)
            },
            {
                field: 'col6',
                headerName: 'Last Purchased',
                minWidth: 125,
                // maxWidth: 125,
                type: 'date',
                valueGetter: (params) => new Date(params.value)
            }
        ]);
    }

    const invoiceAddType = (type) => {
        if (type === "Manually Add Invoice") setAddManInvoice(true)
        else if (type === "Upload PDF/Image") setAutoAddInvoice(true)
        else if (type === "Add Raw Material") setAddingredient(true)
        else if (type === "Upload New Items") setOpenProdUpl(true)
    }

    const onEditClick = () => {
        setEditIngredient(currVals => ({ ...currVals, editEnabled: true }))
    }
    {/*------------------------------------------------------------ CLOSE DIALOG SCENARIOS ------------------------------------------ */ }
    const closeDialog = async (val, reason) => {
        switch (true) {
            case addManInvoice && val === 'success':
            case openRepeatInvoice && val === "success":
                setAddManInvoice(false)
                setOpenRepeatInvoice(false)
                refreshPage()
                setOpenTestSnack({ color: "success", message: "Invoice Added Successfully!", open: true })

                break;
            case addManInvoice && val === 'failure':
                setOpenTestSnack({ color: "error", message: "Error occured when uploading, please try again.", open: true })
                setAddManInvoice(false)

                break;
            case autoAddInvoice && val === 'success':
                setOpenTestSnack({ color: "success", message: "Invoice Successfully Added!", open: true })
                setAutoAddInvoice(false)

                refreshPage()
                break;

            case val === "exitIcon":
                setAddManInvoice(false)
                setOpenNewIngredientInfo(false)
                setOpenRepeatInvoice(false)
                break;

            case val === "success":
                setAddingredient(false)
                setOpenCategorySettings(false)
                setOpenTestSnack({ color: "success", message: `${reason} Added Successfully!`, open: true })
                refreshPage()
                break;

            case val === "ingredient failure":
                setAddingredient(false)
                setEditIngredient(currVals => ({ ...currVals, editEnabled: false }))
                setOpenTestSnack({ color: "error", message: "Error occurred. Please try again.", open: true })
                break;

            case val === 'csvUpload':
                setAutoAddInvoice(false)
                setOpenTestSnack({ color: "success", message: "Successfully uploaded!", open: true })
                break;

            case val === 'pdfUpload':
                // this will need to call invoiceDetails using the 
                setAutoAddInvoice(false)
                if (reason) {
                    const { unknowns } = reason
                    setOpenInvoiceDetails(curVals => ({ ...curVals, openDialog: true, "invNum": unknowns.invoiceNum, "invVendor": unknowns.vendorId }))
                } else {
                    setOpenTestSnack({ color: "info", message: "Processing... please allow 12-24 hours for automatic processing!", open: true })
                }
                break;

            case val === "parDetails":
                setOpenNewIngredientInfo(false)
                setOpenNewUploadIngredientInfo(false)
                setEditIngredient(currVals => ({ ...currVals, editEnabled: false }))
                setOpenTestSnack({ color: "success", message: "Ingredient updated!", open: true })
                refreshPage()
                if (reason === "NEWING") {
                    /// Popup to tell the user to add ing to a recipe in menu
                    setOpenRecipeInform(true)
                }
                break;
            case val === "confirm-new-item":
                setOpenRecipeInform(false)
                navigate("/menu")
                break;
            case reason === 'backdropClick' || val === 'closeUpload':
                setAutoAddInvoice(false)
                break
            case val === "uploadNewIngs":
                setOpenInvoiceDetails(curVals => ({ ...curVals, openDialog: false }))
                setOpenTestSnack({ color: "success", message: "Invoice Added Successfully!", open: true })
                refreshPage()
                if (reason) {
                    handleNewUploadIngredients(reason)
                }
                break;

            case val === "openInvoice":
                setOpenInvoiceDetails(curVals => ({ ...curVals, openDialog: false, "invNum": reason.invNum, "invVendor": reason.invVend }))
                setOpenRepeatInvoice(true)
                setEditUploadedPDF(true)
                refreshPage()
                break;

            default:
                setAddingredient(false)
                setOpenProdUpl(false)
                setOpenCategorySettings(false)
                setEditIngredient(currVals => ({ ...currVals, editEnabled: false }))
                setOpenInvoiceDetails(curVals => ({ ...curVals, openDialog: false }))
                break;
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div className="col-xl-2 col-lg-3 me-auto d-flex align-items-center">
                    <button onClick={onEditClick}
                        className={!enableEdit ? 'col-xl-2 col-lg-3 d-flex align-items-center recipe-dis' : 'col-xl-2 col-lg-3 d-flex align-items-center recipe-bttn'}
                        style={{ width: 'max-content', marginRight: '5px', display: "flex", alignItems: "center", height: '32px' }}
                        disabled={!enableEdit}
                    >
                        <EditIcon fontSize='medium' sx={{ display: "inherit", /*marginRight: "8px",*/ marginLeft: "-2px", "&.MuiSvgIcon-root": { marginRight: '7px' } }} />
                        EDIT</button>
                    <button style={{ marginTop: '0', display: "flex", alignItems: "center", height: '32px' }} className={selIngrIdArr ? "delete-bttn" : "delete-dis"}
                        disabled={!selIngrIdArr}
                        onClick={() => setDeleteOpen(true)}
                    >
                        <DeleteIcon sx={{ color: selIngrIdArr ? "#FF474D" : "lightgray", '&:hover': { color: selIngrIdArr ? "#FF474D" : "lightgray" }, cursor: "pointer" }} fontSize="small" onClick={() => setDeleteOpen(true)} />
                        <span style={{ paddingTop: '2px', paddingLeft: '5px' }}>DELETE</span>
                    </button>
                </div>
                <div style={{ justifyContent: 'flex-start' }}>
                    <InvoiceButton selection={invoiceAddType} />
                </div>
                <GridToolbarQuickFilter
                    variant="outlined"
                    size="small"
                    sx={{
                        border: 'solid #1fb6ff', borderWidth: '1px', borderRadius: '5px', width: '225px', padding: '0', height: '32px', marginBottom: '0',
                        '&.MuiFormControl-root': {
                            margin: '0'
                        }, '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent', // Removes the default border color
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                                backgroundColor: "#1976d20a" // Keeps the border color transparent on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent', // Ensures the border color remains the same when focused
                            },
                            '.MuiSvgIcon-root': { color: '#1fb6ff' },
                            '&.MuiInputBase-root input': { fontSize: 'small', color: '#1fb6ff', padding: '5px 0' }
                        }
                    }}
                />
                <GridToolbarExport sx={{ display: "flex", alignItems: "center", height: '32px' }} />
            </GridToolbarContainer>
        );
    }

    const editStart = ({ field }) => {
        setHoverAnchorEl(false)
        switch (field) {
            case "col1":
                setEditFieldVal({ ...editFieldVal, header: "newIngredientName" })
                break;
            case 'remainingStock':
                setEditFieldVal({ ...editFieldVal, header: "stock" })
                break;
            case 'col7':
                setEditFieldVal({ ...editFieldVal, header: "parThreshold" })
                break;
            case 'storeName':
                setEditFieldVal({ ...editFieldVal, header: "storeName" })
                break;
        }
    }
    {/*--------------------------------------------------------- EDIT TABLE VALUES ------------------------------------------------------------ */ }
    useEffect(() => {
        async function editFieldValFunct() {
            const prev = previousColValues.current
            if (editFieldVal.parVal !== prev.parVal || editFieldVal.stockVal !== prev.stockVal || editFieldVal.ingredientNm !== prev.ingredientNm || editFieldVal.storeName !== prev.storeName || editFieldVal.packagingName !== prev.packagingName) {
                const { success, error } = await PostData(editFieldVal, "api/update-ingredient", false)
                if (success) {
                    refreshPage()
                }
            }
        }
        editFieldValFunct()

    }, [editFieldVal])

    const editCell = (updRow, origRow) => {

        const ingNm = updRow.col1
        const storeNm = updRow.storeName
        const ingId = updRow.id
        const parUpd = updRow.col7

        const numPattern = /(\d+(?:\.\d+)?\s*(\w+))/
        // const numPattern = /(\d+)\s*(\w+)/
        const extractNum = updRow.remainingStock.match(numPattern)
        if (extractNum !== null && extractNum.length >= 3) {
            let packageStr = extractNum[2]

            if (extractNum[2].charAt(extractNum[2].length - 1) === "s") {
                packageStr = extractNum[2].slice(0, -1)
            }
            setEditFieldVal(prevState => ({
                ...prevState,
                ingredientNm: ingNm,
                storeName: storeNm,
                ingredientId: ingId,
                parVal: parUpd,
                stockVal: parseFloat(extractNum[1]),
                packagingName: packageStr
            }));
            return updRow
        }
    }

    const handleNewIngredients = (newItems) => {
        setOpenNewIngredientInfo(true)
        setNewIngredients(newItems)
    }

    const handleNewUploadIngredients = (newItems) => {
        setOpenNewUploadIngredientInfo(true)
        setNewIngredients(newItems)
    }

    const refreshPage = () => {
        setReloadData(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setReloadData(false)
        }, 200);
    }

    const deleteIngredient = async (bool) => {
        setDeleteOpen(false)
        if (bool) {
            const { success: ingredientDeletd, error: deleteErr } = await DeleteData(`api/delete-raw-ingredient`, { "itemIds": selIngrIdArr })

            if (ingredientDeletd) {
                setEnableEdit(false)
                setSelIngrIdArr(null)
                setOpenTestSnack({ color: "success", message: "Successfully deleted ingredient!", open: true })
                refreshPage()
            }
        }
    }

    const selectClick = (e) => {
        if (e.length === 1) {
            setEnableEdit(e)
            setSelIngrIdArr(e)
            const findData = data.find((element) => e[0] === element.ingredientId)
            setEditIngredient(currVals => ({ ...currVals, ingredientId: e[0], vendorId: findData.vendorId }))
        } else if (e.length > 1) {
            setSelIngrIdArr(e)
            setEnableEdit(false)
        } else {
            setEnableEdit(false)
            setSelIngrIdArr(null)
        }
    }

    {/*------------------------------------------------------------ HTML ---------------------------------------------------- */ }
    if (load) {
        return (<div className="container" style={{ left: '40%' }}><CircularProgress /></div>)
    }
    return (
        <>
            {deleteOpen && <ConfirmDelete deleteItem={deleteIngredient} deleteOpen={deleteOpen} />}

            <div className='row' style={{ width: "100%" }}>
                <div className='col-12 menu-list dt' style={{ marginTop: '3px', height: '72vh' }}>
                    <DataGrid
                        checkboxSelection
                        onRowSelectionModelChange={selectClick}
                        rows={row}
                        columns={col}
                        rowHeight={50}
                        processRowUpdate={(updatedRow, originalRow) => editCell(updatedRow, originalRow)}
                        onProcessRowUpdateError={(e) => (console.log(e))}
                        onCellEditStart={editStart}
                        slots={{
                            noRowsOverlay: !load ? () =>
                                <div
                                    style={{
                                        display: 'flex', justifyContent: 'center',
                                        alignItems: 'center', height: '100%', marginLeft: '40px'
                                    }}>
                                    <CircularProgress />
                                </div>
                                :
                                () => <div><button
                                    style={{ display: 'flex', height: '32px', alignItems: 'center', padding: '0 10px' }}
                                    onClick={() => setAddingredient(true)}
                                    className='submit'>Add Raw Material Item</button></div>,
                            toolbar: CustomToolbar
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: 'col6', sort: 'desc' }]
                            }
                        }}
                        sx={{
                            padding: "10px 0 0 25px",
                            border: "none",
                            marginLeft: "10px",
                            overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiDataGrid-main': { fontSize: 'small' },
                            '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiDataGrid-main': { margin: "10px 20px 0 0" },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px", paddingLeft: '0' },
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: "break-spaces",
                                lineHeight: "normal",
                                fontWeight: '600',
                                fontSize: 'small',
                                '@media (max-width:1080px)': { fontSize: '11px' }
                            }
                        }}
                    />
                </div>
            </div>

            {/*------------------------------------------------------------ DIALOG MODALS ------------------------------------------ */}

            {/* This is the dialog for the manual invoice full screen popup */}
            <Dialog fullScreen open={addManInvoice} onClose={closeDialog} >
                <ManualEntry close={closeDialog} newIngredients={handleNewIngredients} />
            </Dialog>

            {/* ----- Upload CSV for Item Add ---- */}
            <Dialog maxWidth={'sm'} open={openProdUpl} onClose={() => setOpenProdUpl(false)} >
                <ProductCsv close={closeDialog} />
            </Dialog>

            <Dialog fullScreen open={openRepeatInvoice} onClose={closeDialog} >
                <ManualEntry close={closeDialog} invoiceOrigin={openInvoiceDetails.invNum} invoiceOriginVendor={openInvoiceDetails.invVendor} uploadEdit={editUploadedPDF} />
            </Dialog>

            <Dialog maxWidth={'md'} fullWidth open={openInvoiceDetails.openDialog} onClose={closeDialog} id="pop-up">
                <DialogContent sx={{ borderRadius: "10px", height: '55vh' }} >
                    <InvoiceDetails invnum={openInvoiceDetails.invNum} invVendor={openInvoiceDetails.invVendor} close={closeDialog} pdfUpload={true} />
                </DialogContent>
            </Dialog >

            <Dialog maxWidth={'sm'} fullWidth open={openNewIngredientInfo} onClose={closeDialog} >
                <NewIngredientParInfo close={closeDialog} ingredients={newIngredients} />
            </Dialog>

            <Dialog maxWidth={'sm'} fullWidth open={openNewUploadIngredientInfo} onClose={closeDialog} >
                <UploadedNewIngredient close={closeDialog} ingredients={newIngredients} />
            </Dialog>
            <Dialog maxWidth={'sm'} open={openRecipeInform} onClose={closeDialog} >
                <InformNewItemRecipe close={closeDialog} />
            </Dialog>

            {/* This is the dialog for the manual Ingredient popup to add an ingredient without an invoice*/}
            <Dialog open={addIngredient} onClose={closeDialog} >
                <DialogContent sx={{ borderRadius: "20px" }} >
                    <AddIngredient close={closeDialog} />
                </DialogContent>
            </Dialog>

            <Dialog open={autoAddInvoice} onClose={closeDialog} >
                <AddPDFFile close={closeDialog} newIngredients={handleNewUploadIngredients} />
            </Dialog>

            <Dialog maxWidth={'sm'} fullWidth open={editIngredient.editEnabled} onClose={closeDialog} >
                <EditRawIngredientInfo ingredientId={editIngredient.ingredientId} vendorId={editIngredient.vendorId} close={closeDialog} tab={pageTab} />
            </Dialog>

            <Dialog maxWidth={'sm'} fullWidth open={openCategorySettings} onClose={closeDialog} >
                <CategorySettings close={closeDialog} />
            </Dialog>
            {/* ------------------------------------------ END ------------------------------------------ */}


            {openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                close={() => setOpenTestSnack({
                    color: "",
                    message: "",
                    open: false
                })} />}
            <div>
                <Popover
                    slotProps={{
                        paper: {
                            style: {
                                backgroundColor: 'white',
                                border: 'solid #14a9f3',
                                borderWidth: '0.2px',
                                color: '#14a9f3',
                                fontSize: 'small'
                            }
                        }
                    }}
                    sx={{
                        pointerEvents: 'none',
                        height: "60px",
                    }}
                    open={openHoverCal}
                    anchorEl={hoverAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => setHoverAnchorEl(false)}
                    disableRestoreFocus
                >
                    <p style={{ padding: "0 10px", marginTop: '5px' }}>Double click to edit</p>
                </Popover>

            </div >
        </>
    );
};