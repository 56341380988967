import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import PostData from "../../services/PostData"
import { FirstUpper } from '../../utils/FirstUpper';
import useFetch from "../../services/useFetch.js"
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { UoMMenuOptions } from '../Helpers/UoMMenuOptions.js';
import HoverOverTootlTip from '../Helpers/HoverOverToolTip.jsx';
import Checkbox from '@mui/material/Checkbox';
import GetData from '../../services/GetData.js';

export default function AddIngredient({ close, location }) {
    const [fndItemUoM, setFoundItemUoM] = useState([]);
    const [menuCategories, setMenuCategories] = useState([])
    const [itemSoldAsIs, setItemSoldAsIs] = useState(false)
    const [posMenuItems, setPosMenuItems] = useState([])

    const { data: serverMenuCategories, error, load: categoryLoad } = useFetch("api/menuCategories", false)
    useEffect(() => {
        setMenuCategories(serverMenuCategories)
    }, [serverMenuCategories])

    const recipeUoM = ["oz", "floz", "tsp", "tbsp", "mL", "grams", "cup", "liter", "units", "none"]

    const vendoPull = useFetch("api/vendors", false, location)
    const vendorData = vendoPull.data

    const [load, setLoad] = useState(false)
    const [inputVal, setInputVal] = useState({
        "itemName": "",
        "supplierId": null,
        "storeName": null,
        "vendor": "",
        "category": "",
        "recipeUoM": null,
        "parUoM": null,
        "itemUnitSize": null,
        "parThreshold": null,
        "parPackaging": null,
        "posMenuItem": null
    })

    const handleInput = ({ target }) => {
        const name = target.value

        let upperName;
        if (target.name === 'itemName' && name.length > 0) {
            upperName = FirstUpper(name)
            setInputVal({ ...inputVal, [target.name]: upperName })

        } else if (target.name === "parThreshold" || target.name === "itemUnitSize") {
            setInputVal({ ...inputVal, [target.name]: parseFloat(name) })

        }
        else {
            setInputVal({ ...inputVal, [target.name]: name })
        }
    }

    const handleRecipeUoM = (event, newValue) => {
        findItemUoM(newValue)
        setInputVal({ ...inputVal, ["recipeUoM"]: newValue })
    }

    const handleVendor = (e, val, reason, rowKey) => {

        if (reason !== 'clear') {
            setInputVal({ ...inputVal, [rowKey]: val })
        }
    }

    const handleSubmit = async (val) => {

        setLoad(true)
        const { success, load, error } = await PostData(inputVal, "api/ingredients", false)

        if (success) {
            setLoad(load)
            close('success', "Ingredient")
        }
        else if (load) {
            return (<div className="container"><CircularProgress /></div>)
        }
        else if (!success) {
            console.log("Success is FALSE")
            close('ingredient failure')
        }
    }

    function findItemUoM(key) {
        // const obj = arr.find(o => o.hasOwnProperty(key))
        const obj = UoMMenuOptions(key)
        if (obj) {
            const val = obj[key]
            setFoundItemUoM(val)
            return
        }
        else { return null }
    }

    const checkItemAsSold = async () => {
        setItemSoldAsIs(!itemSoldAsIs)
        if (!itemSoldAsIs) {
            const { getData: posItems } = await GetData('api/menuItems', null, null, null, null)
            setPosMenuItems(posItems)
        }
    }


    const closePop = () => {
        close(false)
    }
    return (

        <div style={{ height: "82vh", width: "100%", overflow: "hidden" }}>
            <div className='row' >
                <div className='col-9'>
                    <h4 style={{ marginBottom: "0" }}>Add Raw Material: </h4>
                </div>
                <div className='col-3' style={{ position: 'relative', left: "70px" }}>
                    <button type="button" className='blank-bttn'>
                        <CloseIcon onClick={closePop} />
                    </button>
                </div>

            </div>
            <hr />
            <div className='row' style={{ height: '82%', overflowY: "scroll" }}>
                <div className='row'>

                    <div className='row' style={{ marginBottom: "10px", justifyContent: "center", alignItems: "center" }}>
                        <div className='col-4' style={{ paddingLeft: "20px" }}>
                            <label>Item Name:</label>
                        </div>
                        <div className='col-8'>
                            <TextField
                                size="small"
                                variant="outlined" placeholder="Item Name" style={{ width: "288px" }}
                                name='itemName'
                                onChange={handleInput} />
                        </div>

                    </div>

                    <div className='row' style={{ marginBottom: "10px", justifyContent: "center", alignItems: "center" }}>
                        <div className='col-4' style={{ paddingLeft: "20px" }}>
                            <label>Supplier Item Id:</label>
                        </div>
                        <div className='col-8'>
                            <TextField
                                size="small"
                                variant="outlined" placeholder="Item Id" style={{ width: "288px" }}
                                name='supplierId'
                                onChange={handleInput} />
                        </div>
                    </div>

                    <div className='row' style={{ marginBottom: "10px", justifyContent: "center", alignItems: "center" }}>
                        <div className='col-4' style={{ paddingLeft: "20px" }}>
                            <label>Store Name:</label>
                        </div>
                        <div className='col-8'>
                            <TextField
                                size="small"
                                variant="outlined" placeholder="Store Name" style={{ width: "288px" }}
                                name='storeName'
                                onChange={handleInput} />
                        </div>
                    </div>

                    <div className='row' style={{ marginBottom: "10px", justifyContent: "center", alignItems: "center" }}>
                        <div className='col-4' style={{ paddingLeft: "20px" }}>
                            <label>Vendor:</label>
                        </div>
                        <div className='col-8'>
                            <Autocomplete
                                size="small"
                                freeSolo
                                options={vendorData}
                                getOptionLabel={(option) => option.vendor}
                                name='vendor'
                                onInputChange={(e, val, reason) => handleVendor(e, val, reason, 'vendor')}
                                sx={{ width: "288px" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}

                                        placeholder='US Foods, Odeko ...'
                                    />
                                )}>

                            </Autocomplete>

                        </div>
                    </div>


                    <div className='row' style={{ marginBottom: "10px", justifyContent: "center", alignItems: "center" }}>
                        <div className='col-4' style={{ paddingLeft: "20px" }}>
                            <label>Category:</label>
                        </div>
                        <div className='col-8'>
                            <Autocomplete
                                size="small"
                                options={menuCategories || []}
                                loading={menuCategories?.length === 0}
                                name='category'
                                onChange={(e, val) => setInputVal({ ...inputVal, ['category']: val })}
                                sx={{ width: "288px" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder='Categories'
                                    />
                                )}>
                            </Autocomplete>
                        </div>

                    </div>
                    <HoverOverTootlTip message={'Unit of Measure for each individually sold item'} placement={'top'}>
                        <div className='row' style={{ marginBottom: "10px", justifyContent: "center", alignItems: "center" }}>
                            <div className='col-4' style={{ paddingLeft: "20px" }} >

                                <label>Sellable Item UoM:</label>

                            </div>
                            <div className='col-8' /*style={{ paddingLeft: 0 }} */>

                                <Autocomplete
                                    size="small"
                                    options={recipeUoM}
                                    name='recipeUoM'
                                    onChange={handleRecipeUoM}
                                    sx={{ width: "288px" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='grams,units,oz...'
                                        />
                                    )}>
                                </Autocomplete>
                            </div>
                        </div>
                    </HoverOverTootlTip>

                    <HoverOverTootlTip message={'Unit of Measure of Raw Material Packaging'} placement={'top'}>
                        <div className='row' style={{ marginBottom: "10px", justifyContent: "center", alignItems: "center" }}>
                            <div className='col-4' style={{ paddingLeft: "20px" }}>
                                <label>Raw Package UoM:</label>
                            </div>
                            <div className='col-8'>

                                <Autocomplete
                                    size="small"
                                    disabled={!inputVal["recipeUoM"]}
                                    options={fndItemUoM ? fndItemUoM : []}
                                    name='parUoM'
                                    onChange={(e, val) => setInputVal({ ...inputVal, ['parUoM']: val })}
                                    sx={{ width: "288px" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='Choose Recipe UoM to enable.'
                                        />
                                    )}>
                                </Autocomplete>
                            </div>
                        </div>
                    </HoverOverTootlTip>

                    <div className='row' style={{ marginBottom: "10px", justifyContent: "center", alignItems: "center" }}>
                        <div className='col-4' style={{ paddingLeft: "20px" }}>
                            <label>Package Size:</label>
                        </div>
                        <div className='col-8' style={{ paddingRight: '70px' }}>

                            <TextField
                                size="small"
                                type='number'
                                variant="outlined" style={{ width: "288px" }}
                                name='itemUnitSize'
                                placeholder='Unit Size'
                                onChange={handleInput}
                                InputProps={{
                                    endAdornment: inputVal.parUoM && (<InputAdornment position="end">{inputVal.parUoM}</InputAdornment>)
                                }} />
                        </div>
                    </div>

                    <div className='row' style={{ marginBottom: "10px", justifyContent: "center", alignItems: "center" }}>
                        <div className='col-4' style={{ paddingLeft: "20px" }}>
                            <label>Raw Material Packaging:</label>
                        </div>
                        <div className='col-8' style={{ paddingRight: '70px' }}>

                            <TextField
                                size="small"
                                variant="outlined" style={{ width: "288px" }}
                                name='parPackaging' placeholder='Bag, Carton ...'
                                onChange={handleInput}
                            />
                        </div>
                    </div>

                    <div className='row' style={{ marginBottom: "10px", justifyContent: "center", alignItems: "center" }}>
                        <div className='col-4' style={{ paddingLeft: "20px" }}>
                            <label>Reorder Level:</label>
                        </div>
                        <div className='col-8' style={{ paddingRight: '70px' }}>

                            <TextField
                                size="small"
                                variant="outlined" style={{ width: "288px" }}
                                name='parThreshold' placeholder='Reorder at'
                                onChange={handleInput}
                                InputProps={{
                                    endAdornment: (inputVal.parPackaging !== null) &&
                                        (<InputAdornment position="end">{inputVal.parThreshold === 1 ? inputVal.parPackaging : inputVal.parPackaging + 's'}</InputAdornment>)
                                }} />
                        </div>
                    </div>

                    <div className='row' style={{ padding: 0, marginBottom: 10 }}>
                        <div className='col-1' style={{ width: 'max-content', padding: '0 0 0 5%' }}>
                            <Checkbox sx={{ padding: "0" }} checked={itemSoldAsIs} onChange={checkItemAsSold} />
                        </div>
                        <div className='col-8'>
                            <p style={{ margin: '0' }}>Item Sold as Raw Material?</p>
                        </div>
                    </div>
                    {itemSoldAsIs &&
                        <div className='row' style={{ marginBottom: 20 }}>
                            <div className='col-4' style={{ paddingLeft: "20px" }}>
                                <label>POS Item:</label>
                            </div>
                            <div className='col-8'>
                                <Autocomplete
                                    size="small"
                                    options={posMenuItems || []}
                                    getOptionLabel={(option) => option.itemName}
                                    loading={posMenuItems?.length === 0}
                                    name='posItem'
                                    onChange={(e, val) => setInputVal({ ...inputVal, ['posMenuItem']: val ? val['posItemId'] : null })}
                                    sx={{ width: "288px" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                        />
                                    )}>
                                </Autocomplete>
                            </div>
                        </div>
                    }

                    {/* Need a way to insert if the raw material is the sellable product (Banana, Book, etc) */}

                    {/* <div className='row' style={{ marginBottom: "20px", justifyContent: "center", alignItems: "center" }}>
                        <div className='col-4' style={{ paddingLeft: "20px" }}>
                            <label>Inventory Location:</label>
                        </div>
                        <div className='col-8'>

                            <Autocomplete
                                size="small"
                                options={itemLocationOpts}
                                name='inv locations'
                                onChange={(e, val) => setInputVal({ ...inputVal, ['inventoryLocation']: val })}
                                sx={{ width: "288px" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder='Front / Back of house'
                                    />
                                )}>
                            </Autocomplete>
                        </div>
                    </div> */}


                </div>

            </div>
            <hr style={{ margin: "0", marginBottom: '15px' }} />
            <div className='row' style={{ paddingBottom: "20px", justifyContent: 'center', position: "relative" }}>
                <button className='submit' type='submit' style={{ width: "80%" }} onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </div >

    );
};
