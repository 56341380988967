import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid"
import useFetch from '../../../services/useFetch.js'
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteData from '../../../services/DeleteData.js';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { colors, Dialog, Drawer } from "@mui/material";
import AddSku from './AddSku.jsx';
import Chip from '@mui/material/Chip';
import SnackbarEx from '../../Helpers/SnackbarEx.jsx'
import ConfirmDelete from '../../Menu/HelperDialogs/ConfirmDelete.jsx';

export default function ItemSku() {
    const [row, setRow] = useState([])
    const [col, setCol] = useState(null)
    const [openSnackbar, setOpenSnackbar] = useState({
        color: "",
        message: "",
        open: false
    })
    const [reloadData, setReloadData] = useState(false)
    const [selItem, setSelItem] = useState(false)
    const [selIngrIdArr, setSelIngrIdArr] = useState(null)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [openAddNewItem, setOpenAddNewItem] = useState(false)

    const { data: skuData, load, error } = useFetch('api/all-skus', reloadData)

    useEffect(() => {
        setData()
    }, [skuData])

    const renderChip = (val) => {
        return !val.bool ?
            <Chip size={'small'} label={val.str} sx={{ background: val.color, fontWeight: 600 }} /> :
            <Chip size={'small'} label={val.str} sx={{ background: val.color, fontWeight: 600 }} />
    }

    const setData = () => {
        setRow(skuData?.map(e =>
        ({
            id: e.itemId,
            iName: e.itemName,
            type: e.posOrSub,
            status: e.status,
            created: e.dateAdded,
            itemAge: e.dateAdded,
            readyToSell: false
        })
        ) ?? [])
        setCol([
            { field: 'iName', headerName: 'Item Name', flex: 1 },

            {
                field: 'type', headerName: 'Menu Type', flex: 1, renderCell: (p) => {
                    return (p.value === 'pos' ? <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>POS Item</p> : <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>Sub assembly item</p>)
                }
            },
            {
                field: 'status', headerName: 'Status', flex: 1, renderCell: (params) => {
                    return (
                        renderChip(params.value === 'Unsold' ?
                            { bool: false, str: params.value, color: '#ffc1077d' } :
                            { bool: true, str: params.value, color: '#27c27a33' })
                    )
                }
            },
            {
                field: 'created',
                headerName: 'Date Created',
                minWidth: 125,
                // maxWidth: 125,
                type: 'date',
                valueGetter: (params) => new Date(params.value)
            },
            {
                field: 'itemAge', headerName: 'Time on shelf', flex: 1, valueGetter: (params) => {
                    const d1 = new Date(params.value)
                    const d2 = new Date()
                    const dayVal = Math.floor((d2 - d1) / (1000 * 60 * 60 * 24))
                    return (dayVal + (dayVal === 1 ? " day" : " days"));
                }
            },
            {
                field: 'readyToSell', headerName: 'Ready to sell?', flex: 1, renderCell: (params) => {
                    return (
                        renderChip(!params.value ?
                            { bool: false, str: 'Not Ready', color: '#dc35453d' } :
                            { bool: true, str: 'Ready', color: '#27c27a33' })
                    )
                }
            }
        ]);
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ marginRight: "1.3%" }}>
                <div className="col-xl-2 col-lg-3 me-auto d-flex align-items-center">
                    <button style={{ marginTop: '0', display: "flex", alignItems: "center" }} className={selIngrIdArr ? "delete-bttn" : "delete-dis"}
                        disabled={!selIngrIdArr}
                        onClick={() => setDeleteOpen(true)}
                    >
                        <DeleteIcon sx={{ color: selIngrIdArr ? "#dd4f4f" : "lightgray", '&:hover': { color: selIngrIdArr ? "#dd4f4f" : "lightgray" }, cursor: "pointer" }} fontSize="small" onClick={() => setDeleteOpen(true)} />
                        <span style={{ paddingTop: '2px', paddingLeft: '5px' }}>DELETE</span>
                    </button>
                </div>

                <button className={"recipe-bttn"}
                    onClick={() => setOpenAddNewItem(true)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AddOutlinedIcon fontSize='small' sx={{ color: "#1fb6ff", marginRight: '5px', '&:hover': { color: "#14a9f3" } }} />
                        <span style={{ paddingTop: '2px' }}>ADD SKU</span>
                    </div>
                </button>
            </GridToolbarContainer>
        );
    }

    const closeDrawer = (success) => {
        setOpenAddNewItem(false)
        if (success) {
            setOpenSnackbar({ color: "success", message: "SKU added successfully!", open: true })
            refreshPage()
        }
    }

    const selectClick = (e) => {
        if (e.length >= 1) {
            setSelIngrIdArr(e)
        } else {
            setSelIngrIdArr(null)
        }
    }

    const deleteIngredient = async (bool) => {
        setDeleteOpen(false)
        if (bool) {
            const { success: skuDeletd, error: deleteErr } = await DeleteData(`api/delete-skus`, { "itemIds": selIngrIdArr })

            if (skuDeletd) {
                setSelIngrIdArr(null)
                setOpenSnackbar({ color: "success", message: "Successfully deleted skus!", open: true })
                refreshPage()
            }
        }
    }

    const refreshPage = () => {
        setReloadData(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setReloadData(false)
        }, 200);
    }
    /* Dialog that will allow users to set the threshold in which the ingredient needs to be repurchased */
    return (
        <div>
            <Drawer anchor='right' sx={{
                '& .MuiDrawer-paper': {
                    width: 500,  // Set custom width here
                    borderRadius: '10px'
                },
            }} open={openAddNewItem} onClose={() => closeDrawer(false)}>
                <AddSku close={(s) => closeDrawer(s)} />
            </Drawer >
            {deleteOpen && <ConfirmDelete deleteItem={deleteIngredient} deleteOpen={deleteOpen} />}
            <div className='sticky-header'>
                <div className='row pg-hdr' style={{ width: "98%" }}>
                    <div className='col-xl-9 col-lg-8 col-md-8' /*style={{ paddingLeft: '30px' }}*/ >
                        <h2>Current SKUs</h2>
                        <p style={{ marginBottom: '0' }}>View of all internal SKUs</p>
                    </div>
                </div>
                <hr style={{ margin: '0' }} />
            </div>
            <div className="col-12 menu-list" style={{ marginTop: '0' }} >
                <DataGrid
                    checkboxSelection
                    onRowSelectionModelChange={selectClick}
                    columns={col || [{ field: 'Item Name', flex: 1 }, { field: 'Category', flex: 1 }, { field: 'Cost / Item', flex: 1 }]}
                    rows={row}
                    slots={{
                        // noRowsOverlay: CustomNoRowsOverlay,
                        toolbar: CustomToolbar
                    }}
                    rowHeight={35}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    sx={{
                        padding: "10px 0 0 25px", border: "none", overflowY: "scroll",
                        '.MuiToolbar-root': { alignItems: "baseline" },
                        '.MuiSvgIcon-root': { margin: 0 },
                        '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                        '.MuiDataGrid-main': { marginRight: "20px" },
                        '.MuiDataGrid-columnHeaderTitle': { fontSize: 'small', fontWeight: '600', '@media (max-width:1080px)': { fontSize: '11px' } },
                        '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px" },
                        '.MuiDataGrid-cellContent': { fontSize: "small" }
                    }}

                />


            </div>
            {openSnackbar.open &&
                <SnackbarEx color={openSnackbar.color} message={openSnackbar.message} open={openSnackbar.open}
                    close={() => setOpenSnackbar({
                        color: "",
                        message: "",
                        open: false
                    })} />}

        </div>
    );
};
