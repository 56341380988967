import React, { useState } from 'react';
import Radio from '@mui/material/Radio';

export default function SquareMerchantPic({ locOpts, locSel }) {
    const [selectedClass, setSelectedClass] = useState({ name: null, mId: null, address: null })
    const handleRadioChange = ({ target }) => {
        console.log(locOpts)
        const fnd = locOpts.find(val => val.name === target.value)
        setSelectedClass(cur => ({ ...cur, name: target.value, mId: fnd.id, address: fnd.address }));
    }
    console.log(selectedClass)
    const handleSubmit = () => {
        locSel({ id: selectedClass.mId, address: selectedClass.address })
    }
    return (

        <div style={{ height: "50vh", width: "100%", overflow: "hidden" }}>
            <div className='row' style={{ margin: 0, padding: 10 }}>
                <div className='col-9'>
                    <h4 style={{ marginBottom: "0" }}>Select Square Location: </h4>
                </div>
            </div>
            <hr style={{ margin: 0 }} />

            <div className='row' style={{ minWidth: '450px', flexDirection: 'column', height: '36vh', margin: 0, overflowY: 'scroll' }}>
                {locOpts?.map(locOpt => (
                    <div className='row' style={{ padding: '0 30px 5px 30px', alignItems: 'center' }}>
                        <div className='col-1'>
                            <Radio size='small'
                                value={locOpt.name}
                                checked={selectedClass.name === locOpt.name}
                                onChange={handleRadioChange} />
                        </div>
                        <div className='col-11'>
                            <label>{locOpt.name}</label>
                        </div>
                    </div>
                ))}
            </div>

            <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', padding: '10px 0', borderTop: '1px solid #e0e0e0' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <button className='submit' type='submit' style={{ width: "80%" }} onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};
