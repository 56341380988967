import React, { useState, useContext } from 'react';
import SnackbarEx from "../../Helpers/SnackbarEx.jsx";
import { useNavigate } from 'react-router-dom';
import { MenuItem, TextField } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PostData from '../../../services/PostData';
import CircularProgress from '@mui/material/CircularProgress';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';

export default function AddUser({ locationAccess, reset }) {
    const navigate = useNavigate()

    const [load, setLoad] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState({
        color: "",
        message: "",
        open: false
    })
    const [newUserVals, setNewUserVals] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role: "",
        locationAccess: ""
    })


    const handleChange = ({ target }, val) => {
        const value = target.value
        const fldName = target.name

        if (!fldName) {
            setNewUserVals({ ...newUserVals, ['locationAccess']: val })
        }
        else {
            setNewUserVals({ ...newUserVals, [fldName]: value })
        }

    }

    const userRoles = ["GlobalAdmin", "User"]

    const addUser = async () => {
        setLoad(true)
        const { success, error } = await PostData(newUserVals, "api/register", false)
        if (success) {
            setNewUserVals({
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                locationAccess: "",
                role: ""
            })
            setLoad(false)
            setOpenSnackbar({ color: "success", message: "User added successfully!", open: true })

            setTimeout(() => {
                reset()
                navigate("/menu")
            }, 1250)

        } else {
            setLoad(false)
            setOpenSnackbar({ color: "error", message: error.response.data, open: true })
        }
    }

    if (load) {
        return (<div className="container" style={{ left: '15%' }}><CircularProgress size={30} /></div>)
    }


    return (
        <>
            <div>

                <div className='row' style={{ padding: "5px 0 0 15px" }}>
                    <div className='col-12' style={{ padding: '0' }}>
                        <p style={{ fontSize: '20px', height: '30px', paddingLeft: '5px', margin: '0' }}>Add New User: </p>
                        <hr style={{ borderWidth: '0.5px', margin: '0', width: '315px' }} />

                    </div>
                </div>

                <div className='row' style={{ overflowY: "scroll", height: '42vh' }}>
                    <div className='row' style={{ height: "max-content", marginLeft: '0', paddingTop: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <AccountCircle fontSize="medium" sx={{ marginRight: "20px" }} />
                            <TextField size="small" label="First Name"
                                name='firstName' value={newUserVals.firstName} onChange={handleChange}
                                variant='standard' sx={{ width: "275px" }} />
                        </div>

                    </div>
                    <div className='row' style={{ height: "max-content", marginLeft: '0', paddingTop: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <AccountCircle fontSize="medium" sx={{ marginRight: "20px" }} />
                            <TextField size="small" label="Last Name"
                                name='lastName' value={newUserVals.lastName} onChange={handleChange}
                                variant='standard' sx={{ width: "275px" }} />
                        </div>

                    </div>
                    <div className='row' style={{ height: "max-content", marginLeft: '0', paddingTop: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <AccountCircle fontSize="medium" sx={{ marginRight: "20px" }} />
                            <TextField size="small" label="Email"
                                name='email' value={newUserVals.email} onChange={handleChange}
                                variant='standard' sx={{ width: "275px" }} />
                        </div>

                    </div>
                    <div className='row' style={{ height: "max-content", marginLeft: '0', paddingTop: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <LockIcon fontSize="medium" sx={{ marginRight: "20px" }} />
                            <TextField size="small" label="Password" type='password'
                                name='password' value={newUserVals.password} onChange={handleChange}
                                variant='standard' sx={{ width: "275px" }} />
                        </div>

                    </div>

                    <div className='row' style={{ height: "max-content", marginLeft: '0', paddingTop: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <LocationOnIcon fontSize="medium" sx={{ marginRight: "20px" }} />
                            <Autocomplete
                                multiple size="small" label="User Access"
                                options={locationAccess}
                                name='locationAccess'
                                onChange={handleChange}
                                sx={{ width: "275px" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Location Access"
                                        variant='standard'
                                    />
                                )}>
                                {locationAccess.map((val, ind) =>
                                    <MenuItem key={ind} value={val}>{val}</MenuItem>
                                )}
                            </Autocomplete>
                        </div>

                    </div>

                    <div className='row' style={{ height: "max-content", marginLeft: '0', paddingTop: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <VpnKeyIcon fontSize="medium" sx={{ marginRight: "20px" }} />
                            <TextField select size="small" label="User Access"
                                name='role' value={newUserVals.role} onChange={handleChange}
                                variant='standard' sx={{ width: "275px" }}>
                                {userRoles.map((val, ind) =>
                                    <MenuItem key={ind} value={val}>{val}</MenuItem>
                                )}
                            </TextField>
                        </div>

                    </div>

                </div>
                <div className='row' >
                    <button className='submit' type='submit' onClick={addUser}
                        style={{ width: '325px', marginTop: '9.5%', marginLeft: '22px', height: '35px' }}>
                        Add User
                    </button>
                </div>
                {openSnackbar.open &&
                    <SnackbarEx color={openSnackbar.color} message={openSnackbar.message} open={openSnackbar.open}
                        close={() => setOpenSnackbar({
                            color: "",
                            message: "",
                            open: false
                        })} />}
            </div >

        </>
    );
};
