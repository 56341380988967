import React, { useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddBoxIcon from '@mui/icons-material/AddBox';


export default function InvoiceButton({ selection }) {

    const [hamburgerAnchor, setHamburgerAnchor] = useState(null)
    const [hamburgerOpen, setHamburgerOpen] = useState(false)
    const open = Boolean(hamburgerAnchor)

    const onClick = ({ target }) => {
        setHamburgerOpen(!hamburgerOpen)
        setHamburgerAnchor(target)
    }

    const handleClose = (val) => {
        setHamburgerOpen(false)
        selection(val)
    }

    return (
        <div>
            <button type="button" style={{ display: "flex", alignItems: "center", height: '32px' }} onClick={onClick} className="blank-bttn">
                <AddBoxIcon fontSize='large' sx={{ color: "#1fb6ff", '&:hover': { color: "#14a9f3" } }} />
            </button>
            {hamburgerOpen &&
                <Menu
                    anchorEl={hamburgerAnchor}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={(val) => setHamburgerOpen(!hamburgerOpen)}
                    sx={{ ".MuiPaper-root": { borderRadius: "15px" } }}
                >
                    <MenuItem onClick={() => handleClose('Add Raw Material')}>Add Raw Material</MenuItem>
                    <MenuItem disabled onClick={() => handleClose('Upload New Items')}>Upload New Items</MenuItem>
                    <MenuItem onClick={() => handleClose('Manually Add Invoice')}>Manually Add Receipt</MenuItem>
                    <MenuItem onClick={() => handleClose('Upload PDF/Image')}>Upload Receipt</MenuItem>
                </Menu>
            }
            {/* </div> */}

        </div>
    )
}