/* 
    This will need to allow users to add a custom ingredient.
    Ingredient will have downstream unit price affects on other ingredients
    Need the option for the user to select if they are adding POS Menu item as an "ingredient" or an actual ingredient.
    With the menu items that have the same ingredients as the specialty item it will need to know size of menu item and then calculate the unit cost for that item.
*/
import React, { useState, useEffect } from 'react';
import useFetch from "../../../services/useFetch.js"
import { Dialog } from "@mui/material";
import DisplayIngredients from '../posMenuTab/posMenuSubComponents/DisplayIngredients.jsx';
import CustomItemDialog from './CustomItemDialog.jsx';
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid"
import SnackbarEx from '../../Helpers/SnackbarEx.jsx';
import { currencyFormatter } from '../../Helpers/CurrencyFormat.js';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditCustomItem from './bulkCustomItem/EditCustomItem.jsx';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from '../HelperDialogs/ConfirmDelete.jsx';
import DeleteData from '../../../services/DeleteData.js';

export default function SpecialtyMenu() {

    const [reloadData, setReloadData] = useState(false)
    const [row, setRow] = useState([])
    const [col, setCol] = useState(null)
    const [selItem, setSelItem] = useState(false)
    const [selIngrIdArr, setSelIngrIdArr] = useState(null)
    const [ingredientsHeader, setIngredientsHeader] = useState(null)
    const [deleteIcon, setDeleteIcon] = useState(true)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [openAddNewItem, setOpenAddNewItem] = useState(false)
    const [openEditItem, setOpenEditItem] = useState(false)
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })
    const { data: customMenuData, load, error } = useFetch('api/custom-menu-item', reloadData)
    useEffect(() => {
        setData()
    }, [customMenuData])

    const setData = () => {
        setRow(customMenuData?.map(e =>
        ({
            id: e.itemId,
            iName: e.itemName,
            category: e.category,
            itemType: e.type,
            sittingInv: e.sellableInventory,
            productionCost: e.productionCost,
            prodAging: e?.ageFactor ?? 0
        })
        ) ?? [])
        setCol([
            { field: 'iName', headerName: 'Item Name', flex: 1 },

            { field: 'category', headerName: 'Cost Category', flex: 1 },
            {
                field: 'itemType', headerName: 'Item Type', flex: 1, renderCell: (params) => {
                    if (params.value === "bulkMenuItem") { return <span style={{ fontWeight: '600', fontSize: 'small' }}>Bulk Menu Item</span> }
                    else { return <span style={{ fontWeight: '600', fontSize: 'small' }}>Custom Ingredient</span> }
                }
            },
            { field: 'sittingInv', headerName: 'Sitting Inventory Level', flex: 1 },
            {
                field: 'productionCost', headerName: 'Cost / Single Item', flex: 1,
                valueFormatter: ({ value }) => {
                    return value !== null ? currencyFormatter(value, 2) : 'None'
                }
            }, {
                field: 'prodAging', headerName: 'Aging Factor', flex: 1,
                renderCell: (params) => {
                    return (
                        <span style={{ fontWeight: '600', fontSize: 'small' }}>
                            {params.value !== null ? `${params.value} days` : 'None'}
                        </span>
                    );
                },
            }
        ]);
    }

    const handlePopupClose = async (val) => {
        if (val === "success") {
            setOpenAddNewItem(false)
            setReloadData(true)
            setTimeout(() => {
                // Code to be executed after the delay
                setReloadData(false)
            }, 200);
            setOpenTestSnack({ color: "success", message: "Menu item added!", open: true })
        } else if (val === "edit") {
            setOpenAddNewItem(false)
            setReloadData(true)
            setTimeout(() => {
                // Code to be executed after the delay
                setReloadData(false)
            }, 200);
            setOpenTestSnack({ color: "success", message: "Menu item updated!", open: true })
        }
        else if (val === "error") {
            setOpenAddNewItem(false)
            setOpenTestSnack({ color: "error", message: "An error occurred.", open: true })
        }
        setOpenAddNewItem(false)
        setOpenEditItem(false)
    }

    const CustomNoRowsOverlay = () => {
        return (
            <div style={{ position: 'relative', left: '42%', top: '35%' }}>
                {/* <p style={{ margin: '0', marginLeft: '15px' }}>No Data yet...</p> */}
                <button onClick={() => setOpenAddNewItem(true)} className='submit' style={{ width: '125px', height: '35px', color: "#ebfafc", fontSize: 'medium' }}>
                    Add New Item
                </button>
            </div>
        )
    }

    const deleteCustItem = async (bool) => {
        setDeleteOpen(false)
        if (bool) {
            const { success: ingredientDeletd, error: deleteErr } = await DeleteData(`api/delete-custom-item`, { "itemIds": selIngrIdArr })

            if (ingredientDeletd) {
                setSelItem(false)
                setSelIngrIdArr(null)
                setIngredientsHeader(null)
                refreshPage()
                setOpenTestSnack({ color: "success", message: "Successfully deleted item!", open: true })
            }
        }
    }

    const cellClick = (e) => {
        const findItem = customMenuData?.find((elem) => e[0] === elem.itemId)

        if (e.length === 1) {
            /* NOTE: e [index] === the item id */
            setIngredientsHeader(findItem.itemName)
            setSelIngrIdArr(e)
            setSelItem(e[0])
        } else if (e.length > 1) {
            setSelIngrIdArr(e)
            setSelItem(false)
        } else {
            setSelIngrIdArr(null)
            setSelItem(false)
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ marginRight: "1.3%" }}>
                <div className="col-xl-2 col-lg-3 me-auto d-flex align-items-center">
                    <button style={{ marginTop: '0', display: "flex", alignItems: "center" }} className={selIngrIdArr ? "delete-bttn" : "delete-dis"}
                        disabled={!selIngrIdArr}
                        onClick={() => setDeleteOpen(true)}
                    >
                        <DeleteIcon sx={{ color: selIngrIdArr ? "#dd4f4f" : "lightgray", '&:hover': { color: selIngrIdArr ? "#dd4f4f" : "lightgray" }, cursor: "pointer" }} fontSize="small" onClick={() => setDeleteOpen(true)} />
                        <span style={{ paddingTop: '2px', paddingLeft: '5px' }}>DELETE</span>
                    </button>
                </div>

                <button style={{ marginTop: '0' }} className={selItem ? "recipe-bttn" : "recipe-dis"}
                    disabled={!selItem}
                    onClick={() => setOpenEditItem(true)}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <EditIcon fontSize='small' sx={{ color: selItem ? "#1fb6ff" : "lightgray", '&:hover': { color: "#14a9f3" } }} />
                        <span style={{ paddingTop: '2px', paddingLeft: '5px' }}>EDIT ITEM</span>
                    </div>
                </button>

                <button className={"recipe-bttn"}
                    onClick={() => setOpenAddNewItem(true)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AddOutlinedIcon fontSize='small' sx={{ color: "#1fb6ff", marginRight: '5px', '&:hover': { color: "#14a9f3" } }} />
                        <span style={{ paddingTop: '2px' }}>ADD RECIPE</span>
                    </div>
                </button>
            </GridToolbarContainer>
        );
    }

    const refreshPage = () => {
        setReloadData(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setReloadData(false)
        }, 200);
    }

    if (load) {
        return (<div className="container" style={{ top: "45px", left: "45%" }}><CircularProgress /></div>)
    }
    return (
        <>
            {deleteOpen && <ConfirmDelete deleteItem={deleteCustItem} deleteOpen={deleteOpen} />}
            <Dialog maxWidth={'lg'} fullWidth open={openAddNewItem} onClose={() => setOpenAddNewItem(false)}>
                <CustomItemDialog close={handlePopupClose} />
            </Dialog >
            <Dialog maxWidth={'lg'} fullWidth open={openEditItem} onClose={() => setOpenEditItem(false)}>
                <EditCustomItem close={handlePopupClose} menuId={selItem} />
            </Dialog >

            <div className="col-12 menu-list" style={{ marginTop: '0' }} >
                <DataGrid
                    checkboxSelection
                    columns={col || [{ field: 'Item Name', flex: 1 }, { field: 'Category', flex: 1 }, { field: 'Cost / Item', flex: 1 }]}
                    rows={row}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        toolbar: CustomToolbar
                    }}
                    rowHeight={35}
                    // onRowClick={cellClick}
                    onRowSelectionModelChange={cellClick}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    sx={{
                        padding: "10px 0 0 25px", border: "none", overflowY: "scroll",
                        '.MuiToolbar-root': { alignItems: "baseline" },
                        '.MuiSvgIcon-root': { margin: 0 },
                        '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                        '.MuiDataGrid-main': { marginRight: "20px" },
                        '.MuiDataGrid-columnHeaderTitle': { fontSize: 'small', fontWeight: '600', '@media (max-width:1080px)': { fontSize: '11px' } },
                        '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px" },
                        '.MuiDataGrid-cellContent': { fontSize: "small" }
                    }}

                />


            </div>
            <hr />
            <div className='row' style={{ width: "100%", margin: '10px 0 0 20px' }}>
                <div className="col-xl-10 col-lg-9 col-md-9" >
                    <p style={{ margin: '0', fontWeight: "800", fontSize: '16px' }}>{ingredientsHeader} Ingredients:</p>
                </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 " style={{ marginBottom: '75px' }}>
                <div className="row mu-det" style={{ justifyContent: "space-around", paddingRight: '3%' }}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ing-menu-mods" style={{ overflowY: 'auto', maxHeight: '350px', width: '100%' }}>

                        {(selItem !== false && selItem !== null) ?
                            <DisplayIngredients ingredientId={selItem} refresh={reloadData} /> :
                            <>
                                <strong
                                    style={{ margin: "10px 10px 0 0", fontWeight: "bold", fontSize: "large", height: "fit-content" }}>
                                    Custom Item Ingredients
                                </strong>
                                <p style={{ position: "relative" }}>select an item to display</p>
                            </>
                        }
                    </div>
                </div>
            </div>

            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }

        </>
    );
};
