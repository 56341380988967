import React, { useEffect } from 'react';
import { useLocation } from '../../context/LocationContext';
import MenuItem from '@mui/material/MenuItem';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

export default function UserLocation({ locationOptns, change }) {
    const usrLocs = localStorage.getItem('usrlctn')
    const { selectedLocation, setSelectedLocation } = useLocation();
    const locCount = locationOptns.length

    useEffect(() => {
        change()
    }, [selectedLocation])

    const handleChange = ({ target }) => {
        const value = target.value
        setSelectedLocation(value)
    }

    return (
        <>
            {(locCount > 1) ?
                <>
                    <TextField
                        select
                        size='small'
                        // variant='standard'
                        onChange={(e) => handleChange(e)}
                        value={usrLocs}
                        sx={{ width: '215px', marginLeft: '5.5%', color: 'gray' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PlaceOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                    >
                        {locationOptns.map((loc, ind) => (
                            <MenuItem key={ind} value={loc}>
                                {loc}
                            </MenuItem>
                        ))}

                    </TextField>
                </>
                :

                <>
                    <TextField defaultValue={usrLocs}
                        size='small'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PlaceOutlinedIcon />
                                </InputAdornment>
                            ),
                            readOnly: true
                        }}
                        sx={{ width: '215px', marginLeft: '5.5%', color: 'gray', '.MuiFormControl-root': { border: 'solid blue' }, '.MuiInputBase-root input': { textOverflow: 'ellipses' } }} />
                </>
            }
        </>

    );
};
