import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

export default function SkuAddChecklist({ recipeData }) {
    const [itemCheck, setItemCheck] = useState([])
    useEffect(() => {
        const checks = recipeData?.map(() => (
            false
        ))
        setItemCheck(checks)
    }, [recipeData])

    if (!recipeData) {
        return (
            <>
                <div className='row' style={{ margin: 0, padding: 0, borderBottom: 'solid lightgray', borderTop: 'solid lightgray' }}>
                    <div className='col-4' style={{ borderRight: 'solid lightgray' }}>
                        <p style={{ margin: 0, padding: '10px 0 5px 0' }}>Completed</p>
                    </div>
                    <div className='col-8'>
                        <p style={{ margin: 0, padding: '10px 0 5px 0' }}>Recipe Step</p>
                    </div>
                </div>
                <div style={{ position: 'relative', top: '30%', left: '40%' }}>
                    <CircularProgress size={30} sx={{ marginLeft: "40px" }} />
                    <p style={{ color: '#1976d2' }}>Getting checklist ...</p>
                </div>

            </>
        )
    }

    const checkBx = (ind) => {
        const newArray = [...itemCheck]; // Create a shallow copy of the current array
        newArray[ind] = !itemCheck[ind]; // Update the specific index
        setItemCheck(newArray);
    }
    return (
        <>
            <div className='row' style={{ margin: 0, padding: 0, borderBottom: 'solid lightgray', /*borderTop: 'solid lightgray'*/ }}>
                <div className='col-3' style={{ borderRight: 'solid lightgray' }}>
                    <p style={{ margin: 0, padding: '10px 0 10px 0', fontWeight: 500 }} >Completed</p>
                </div>
                <div className='col-9'>
                    <p style={{ margin: 0, padding: '10px 0 10px 0', fontWeight: 500 }}>Recipe Step</p>
                </div>
            </div>
            <div className='row '
                style={{
                    margin: 0,
                    height: '65vh',
                    flexDirection: 'column',
                    padding: 0
                }}>
                {recipeData?.length === 0 ? (
                    <div style={{ margin: '15% 40%' }}>
                        <p>No Recipe</p>
                    </div>
                )
                    :
                    recipeData?.map((elem, indx) =>
                    (
                        <div className='row' style={{ margin: 0, borderBottom: 'solid #d3d3d375', padding: 0, overflowY: 'scroll', }}>
                            <div className='col-3' style={{ borderRight: 'solid #d3d3d375' }}>
                                <Checkbox checked={itemCheck[indx]} onChange={() => checkBx(indx)} />
                            </div>
                            <div className='col-9' style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className='row'>
                                    <p style={{ margin: 0 }}>{elem.name}</p>
                                </div>
                                <div className='row' style={{ paddingLeft: '10px' }}>

                                    <p style={{
                                        width: 'fit-content', padding: '0 0 0 10px', fontSize: 'small', marginBottom: '5px'
                                    }}><i>{elem.units[0].amount}</i></p>
                                    < span style={{ width: 'fit-content', fontSize: 'small', paddingLeft: '5px' }}>
                                        <i>{elem.units[0].measurement}</i>
                                    </span>

                                </div>
                            </div>
                        </div>
                    )
                    )}
            </div >
        </>
    );
};
