import React, { useState, useContext } from 'react';
import { authContext } from '../../context/AuthContext.js';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DynamicEntry from './DynamicEntry';
import InputAdornment from '@mui/material/InputAdornment';
import ManualEntryHeader from './ManualEntryHeader';
import PostInvoice from '../../services/PostInvoice';
import SnackbarEx from '../Helpers/SnackbarEx';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

export default function ManualEntry({ close, newIngredients, invoiceOrigin, invoiceOriginVendor, uploadEdit }) {
    const { userRole } = useContext(authContext)
    const [autoManualCheck, setAutoManualCheck] = useState(false)

    const [invoiceSum, setInvoiceSum] = useState(0)
    const [submitLoad, setSubmitLoad] = useState(false)

    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const [invoiceHeader, setInvoiceHeader] = useState([{
        vendor: "",
        vendorId: null,
        street: "",
        city: "",
        zip: "",
        state: "",
        invoiceNum: "",
        date: ""
    }])

    const [invoiceBodyData, setInvoiceBodyData] = useState([
        {
            ingredient: "",
            ingredientId: "",
            category: null,
            vendorItemId: null,
            recipeUoM: "",
            itemUoM: "",
            itemSize: null,
            pack: "",
            packQuantity: null,
            packUnitQuantity: null,
            recipeIngQuantity: null, //Will get calculated in another function using the itemUoM, recipeUoM, and itemSize            
            quantity: null,
            total: null
        }
    ]);

    const [addedCosts, setAddedCosts] = useState({
        discount: 0.0,
        tax: 0.0,
        shipping: 0.0,
    })

    const handleClose = () => {
        close('exitIcon')
    }

    const handleAmountChange = (sum) => {
        setInvoiceSum(sum)
    }

    const postToApi = async () => {
        const valid = validateForm()
        if (valid) {
            const autoMan = !autoManualCheck ? "manual" : "auto"
            setSubmitLoad(true)
            const { success, error, retIngredients } = await PostInvoice(
                { "invHeader": invoiceHeader, "invBody": invoiceBodyData, "invoiceTotal": invoiceSum, "addedCosts": addedCosts },
                `api/invoice/${uploadEdit ? "pdfUpload" : autoMan}`, false)
            if (success) {
                setSubmitLoad(false)
                close('success')
                if (retIngredients.length > 0) {
                    newIngredients(retIngredients)
                }
            }
            else if (error) {
                setSubmitLoad(false)
                const errMessage = error.response.data
                setOpenTestSnack({ color: "error", message: errMessage, open: true })
            }
        }
        else {
            setOpenTestSnack({ color: "error", message: "Please fill out invoice fields.", open: true })
        }
    }

    const updateBody = (value, index, del, obj) => {
        if (del) {
            setInvoiceBodyData(value)
        } else if (obj === "addedCost") {
            setAddedCosts(cur => ({ ...cur, [index]: Number(value) }))
        } else {
            invoiceBodyData[index] = value[index];
            setInvoiceBodyData(invoiceBodyData);
        }
    };
    const updateHeader = (value) => {
        setInvoiceHeader(value)
    };

    const validateForm = () => {
        const allBodyFilled = invoiceBodyData.every(value => value.ingredient
            && value.recipeUoM && value.itemUoM && value.itemSize && value.pack && value.packQuantity && value.packUnitQuantity
            /*&& value.recipeIngQuantity*/ && value.total !== null && value.total >= 0);
        const allHeaderFiller = invoiceHeader.every(value => value.vendor && value.street && value.city
            && value.zip && value.state && value.invoiceNum && value.date)
        if (allBodyFilled && allHeaderFiller) {
            return true
        }
        else { return false };
    };

    return (
        <div className='row' style={{ height: 'auto' }}>
            <div className='row inv-hdr' style={{ alignItems: 'baseline' }}>
                <div className='col-10' >
                    <h4 style={{ margin: "20px 0 20px 5%" }}>Add Invoice</h4>
                </div>
                <div className='col-2' style={{ position: 'relative', left: '10%' }}>
                    <button type='button' className='blank-bttn' onClick={handleClose}>
                        <CloseIcon sx={{ cursor: 'pointer' }} />
                    </button>
                </div>
            </div>

            <div className='row' style={{
                flexDirection: 'column',
                marginTop: "15px", boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                minHeight: "82vh", height: 'auto', width: '90%', marginLeft: '4.7%', borderRadius: '5px'
            }}>
                <div className='row' style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <ManualEntryHeader updateHeader={updateHeader} invoiceOrigin={invoiceOrigin} invOriginVendor={invoiceOriginVendor} uploadEdit={uploadEdit} />
                </div>
                <hr style={{ margin: '20px 0', height: '1.5px', color: '#0000cd', backgroundColor: '#0000cd' }} />
                <div className='row'>

                    <div className='col-12'>
                        <div className='row inv-ingredients'>
                            <DynamicEntry
                                invoiceSum={handleAmountChange}
                                updateBody={updateBody}
                                invoiceOrigin={invoiceOrigin}
                                invOriginVendor={invoiceOriginVendor}
                                selectedVendor={invoiceHeader[0].vendorId} />
                        </div>
                        <div className='row'>
                            {/* Date */}
                        </div>
                    </div>
                </div>



                {/* This is the Total and Submit section of the invoice entry log */}
                <div className='row' style={{ marginBottom: '15px' }} >
                    <div className='col-12'>
                        <div className='row' id="inv-tot" >

                            <div className='col-xl-1 col-lg-2 col-md-2 col-sm-2'>
                                <p style={{ fontSize: 'small', margin: '0 0 0 40px' }}>Total: </p>
                            </div>

                            {/* This is the total amount disabled input*/}
                            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-4'>
                                <TextField disabled={true} variant="standard" value={invoiceSum.toFixed(2)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "medium" } }} />
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ position: 'relative', alignItems: 'end', justifyContent: 'end' }}>

                        {userRole === "AppAdmin" ?
                            <div className='col-xl-6 col-lg-6 col-md-2 col-sm-2' style={{ margin: '10px 10px 0 0' }}>
                                <div className='row' style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <div className='col-3' style={{ width: 'max-content', padding: '0' }}>
                                        <label>Auto</label>
                                        <Checkbox sx={{ padding: "0" }} checked={autoManualCheck} onChange={() => setAutoManualCheck(!autoManualCheck)} />
                                    </div>
                                    <div className='col-4' style={{ padding: '0' }}>
                                        <button type='submit'
                                            onClick={postToApi}
                                            style={{
                                                border: 'none', borderRadius: '10px', backgroundColor: '#1fb6ff',
                                                color: 'white', width: '100%', height: '40px'
                                            }}>
                                            {submitLoad ? <CircularProgress size={20} sx={{ color: 'white', marginTop: '5px' }} /> : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2' style={{ margin: '10px 10px 0 0' }}>
                                <button type='submit'
                                    onClick={postToApi}
                                    style={{
                                        border: 'none', borderRadius: '10px', backgroundColor: '#1fb6ff',
                                        color: 'white', width: '100%', height: '40px'
                                    }}>
                                    {submitLoad ? <CircularProgress size={20} sx={{ color: 'white', marginTop: '5px' }} /> : "Submit"}
                                </button>
                            </div>}
                    </div>
                </div>
            </div>

            {
                openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                    close={() => setOpenTestSnack({
                        color: "",
                        message: "",
                        open: false
                    })} />
            }
        </div >
    );
};
