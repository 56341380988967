export function UoMMenuOptions(selectedUoM) {

    try {
        const itemUoM = [
            { "oz": ["grams", "lbs", "gallons", "oz"] },
            { "floz": ["tbsp", "cup", "gallons", "pints", "quarts", "liter", "floz"] },
            { "lbs": ["grams", "oz", "lbs"] },
            { "grams": ["oz", "lbs", "grams"] },
            { "cup": ["lbs", "gallons", "fl_oz"] },
            { "units": ["units"] },
            { "quarts": ["floz", "gallons", "cup"] },
            { "mL": ["liter"] },
            { "liter": ["mL", "floz"] },
            { "none": ["none"] }]
        const obj = itemUoM.find(o => o.hasOwnProperty(selectedUoM))
        return obj
    } catch (err) {
        console.log(err)
        return []
    }
}