import React, { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
// import PostData from '../../../../services/PostData.js'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog } from '@mui/material';
import PostDataRetData from '../../../../services/PostDataRetData.js';
import SquareMerchantPic from './SquareMerchantPic'

export default function SquareAuth() {

    const location = useLocation();
    const [codeParam, setCodeParam] = useState(null)
    const [nav, setNav] = useState(false)
    const [load, setLoad] = useState(false)
    const [openLocationPicker, setOpenLocationPicker] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code')

        if (code) {
            setCodeParam(code)
        }
    }, [location.search])

    useEffect(() => {
        if (codeParam) {
            postCode();
        }
    }, [codeParam]);

    const postCode = async () => {
        setLoad(true)
        // Will need to load a Dialog if more than 1 location, else
        // const { success, error } = await PostData({ code: codeParam }, 'api/square-oauth', false /*, usrLocs[0]*/)
        const { data: numLocs, error } = await PostDataRetData({ code: codeParam }, 'api/square-oauth', false)

        if (numLocs.message === "Success") {
            setNav(true)
        } else {
            setOpenLocationPicker(numLocs.message)
        }
    }

    const postMerchantId = async (mId) => {
        const { data: numLocs, error } = await PostDataRetData({ code: codeParam, locId: mId.id, address: mId.address }, 'api/square-oauth/update-merchant', false)
        if (numLocs.message === "Success") {
            setNav(true)
        }
    }

    return (
        <>
            <Dialog open={openLocationPicker} close={(mId) => postMerchantId(mId)} >
                <SquareMerchantPic locOpts={openLocationPicker} locSel={(mId) => postMerchantId(mId)} />
            </Dialog>
            <Backdrop
                sx={{ flexDirection: 'column', color: 'rgb(31, 182, 255)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={load}
                onClick={() => setLoad(false)}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
                <p style={{ marginTop: "8px", color: "#fff" }}>Downloading menu...</p>
            </Backdrop>

            <div style={{ position: 'relative', top: '50%', left: '40%' }}>
                <p style={{ color: 'lightgray' }}>Processing...</p>
            </div>
            {nav && (
                <Navigate to="/inventory" replace={true} />
            )}
        </>
    );
};
