import React, { useState, useContext } from 'react'
import { SidebarContext } from '../../context/SidebarContext.js';
import { LocationContext } from "../../context/LocationContext.js"
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import AccountDropdown from '../../components/Account/AccountDropdown';
import LogoutUser from '../../components/Authorization/Logout.js';
import Bread_Bskt from "../../images/Bread_Bskt.png"
import { useNavigate } from 'react-router-dom';
import UserLocation from '../../components/Account/UserLocation.jsx';

export default function MobileHeader({ logout, usrName, locations, reload, usrRole }) {

    const { toggleSidebar, isOpen } = useContext(SidebarContext)
    const { locationAccess } = useContext(LocationContext)
    const navigate = useNavigate()

    const [accntBttn, setAccntBttn] = useState(false)
    const [logoTarget, setLogoTarget] = useState(null)
    const [openSnackbar, setOpenSnackbar] = useState(false)

    const handleAcctDrop = ({ target }) => {

        setAccntBttn(!accntBttn)
        setLogoTarget(target)
    }

    const handleClose = async (selection) => {
        setAccntBttn(!accntBttn)


        switch (selection) {
            case 'logout':
                const { success } = await LogoutUser()
                if (success) {

                    sessionStorage.removeItem("urtkn")
                    return logout(true)
                }
                else { return setOpenSnackbar(true) }

            case 'settings':
                if (isOpen) {
                    toggleSidebar()
                }

                navigate("/account-settings/user-profile")
                //navigate to the account page -- somehow disable the sidebar
                break;
        }
    }

    const goHome = () => {

        if (!isOpen) {
            toggleSidebar()
        }
        navigate("analytics")
    }

    const handleLocChange = () => {
        reload()
    }

    const handleSidebarMenuClick = () => {
        toggleSidebar()
    }

    return (
        <div className="header">

            < div className='row' style={{ alignItems: "center" }}>
                < div className='col-2'>
                    <div className='row' style={{ alignItems: 'center' }}>
                        <div className='col-2' id='colpse-sdbr' style={{ alignSelf: "center", maxWidth: '103px', minWidth: '95px', padding: '0' }}>
                            {isOpen ? <MenuOpenOutlinedIcon sx={{ color: '#5f6368', marginLeft: "40px", height: '1.1em', width: '1.1em', cursor: 'pointer', '@media (max-width:1080px)': { marginLeft: "27px" } }}
                                onClick={handleSidebarMenuClick} /> :
                                <MenuOutlinedIcon sx={{ color: '#5f6368', marginLeft: "40px", height: '1.1em', width: '1.1em', cursor: 'pointer', '@media (max-width:1080px)': { marginLeft: "27px" } }}
                                    onClick={handleSidebarMenuClick} />
                            }
                        </div>
                    </div>
                </div>
                <div className='col-10' style={{ position: "relative" }}>
                    <div className='row' style={{ alignItems: 'center' }}>
                        <div className='col-9'>
                            <UserLocation locationOptns={locationAccess} change={handleLocChange} />
                        </div>
                        <div className='col-2' style={{ borderLeft: 'solid lightgray' }}>
                            <button type='button' className='blank-bttn' style={{ position: 'relative' }} onClick={(e) => handleAcctDrop(e)} >

                                <Avatar sx={{ background: 'lightgray', color: "black" }}>{usrName[0]}</Avatar>
                            </button>
                        </div>
                    </div>
                    {accntBttn && <AccountDropdown target={logoTarget} close={handleClose} />}
                </div>

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}>
                    <Alert
                        onClose={() => setOpenSnackbar(false)}
                        severity="error"
                    >
                        Unable to log out!
                    </Alert>
                </Snackbar>

            </div>
        </div >
    )
}