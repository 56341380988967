
import React, { useEffect, useState, useContext } from "react";
import { LocationContext } from "../../../context/LocationContext.js";
import { Chip, Dialog } from "@mui/material";
import AddItem from "./posMenuSubComponents/AddItem.jsx";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useFetch from "../../../services/useFetch.js"
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid"
import SnackbarEx from "../../Helpers/SnackbarEx.jsx";
import MenuActionsButton from "./MenuActionsButton.jsx";
import GetData from "../../../services/GetData.js";
import EditMenuIngredients from "./posMenuSubComponents/EditMenuIngredients.jsx";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import RefreshPOSMenu from "../HelperDialogs/RefreshPOSMenu.jsx";
import DeleteData from "../../../services/DeleteData.js";
import ConfirmDelete from "../HelperDialogs/ConfirmDelete.jsx";
import DisplayIngredients from "./posMenuSubComponents/DisplayIngredients.jsx";
import DisplayModifiers from "./posMenuSubComponents/DisplayModifiers.jsx";
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import CopyRecipes from "../HelperDialogs/CopyRecipes.jsx";
import NoRecipeItems from "../HelperDialogs/NoRecipeItems.jsx";
import CopyMenuItemRecipes from "../HelperDialogs/CopyMenuItemRecipes.jsx";
import WasteFactorConf from "../HelperDialogs/WasteFactorConf.jsx";

const apiUrl = "api/menuItems"
const apiRecipeUrl = "api/menuItemsRecipes"

export default function PosMenu({ reload }) {
    const { isDummyLocation } = useContext(LocationContext)
    const [openAddItem, setOpenAddItem] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState({
        color: "",
        message: "",
        open: false
    })
    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [ingredientsHeader, setIngredientsHeader] = useState(null)
    const [selSingleIngredient, setSelSingleIngredient] = useState(null)

    const [selIngrIdArr, setSelIngrIdArr] = useState(null)

    const [selCategory, setSelCategory] = useState(null)

    const [reloadData, setReloadData] = useState(false)
    const [ingredientStat, setIngredientStat] = useState(null)

    const [emptyData, setEmptyData] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [deleteIcon, setDeleteIcon] = useState(false)
    const [posOpen, setPosOpen] = useState(false)
    const [openWasteFactor, setOpenWasteFactor] = useState(false)
    const [copyRecipeOpen, setCopyRecipeOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [date1, setDate1] = useState(null)
    const [date2, setDate2] = useState(null)
    const [load, setLoad] = useState(true)
    const [posLoad, setPosLoad] = useState(false)

    const [noRecipeItem, setNoRecipeItem] = useState({
        "openDialog": false,
        "itemNames": null
    })

    /*Fetches the data menu items to be displayed in the table below */
    const { data, error } = useFetch(apiUrl, reloadData)
    const { data: recipes, error: recipesError } = useFetch(apiRecipeUrl, reloadData)

    useEffect(() => {
        setReloadData(true)
        setSelSingleIngredient(false)
        setTimeout(() => {
            // Code to be executed after the delay
            setReloadData(false)
        }, 500);
    }, [reload])


    //This is going to handle the pop up to add a menu item to the database
    const handlePopupClose = async (close, success) => {

        setOpenAddItem(false)
        if (close === 'close') {
            setOpenAddItem(close)
            setNoRecipeItem(cur => ({ ...cur, ["openDialog"]: false }))
        }
        else if (success === 'success') {
            setOpenSnackbar({ color: "success", message: "Item Added Successfully!", open: true })
            setReloadData(true)
            setOpenEdit(false)
            refreshPage()
        } else if (success === 'copied') {
            setCopyRecipeOpen(false)
            setOpenSnackbar({ color: "success", message: "Item Copied Successfully!", open: true })
            setReloadData(true)
            refreshPage()
        }
        else if (success === 'error') {
            setOpenEdit(false)
            setOpenSnackbar({ color: "error", message: "An Error Occurred.", open: true })
        }
        else if (!close) {
            setCopyRecipeOpen(false)
            setOpenEdit(false)
        } else {
            setNoRecipeItem(cur => ({ ...cur, ["openDialog"]: false, ["itemNames"]: null }))
            refreshPage()
        }
    }

    const refreshPage = () => {
        setReloadData(true)
        setTimeout(() => {
            // Code to be executed after the delay
            setReloadData(false)
        }, 200);
    }

    useEffect(() => {
        if (data !== null && recipes) {
            setData()
            setLoad(false)
        }
    }, [data, recipes])

    const arrowClick = async (e, direction, rowVal) => {
        e.stopPropagation()
        const dir = direction === 'up' ? 'up' : 'down'
        const { getData } = await GetData(`api/upload-invoice/${rowVal}/pos/${dir}`, null, null, null, null)
        if (getData.success) {
            refreshPage()
        }
    }
    const CustomCellRenderer = ({ value, rowVal }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: 38 }}>
                {/* <div style={{ marginLeft: '0px' }}>{icon}</div> */}
                <div className='col-8' style={{ margin: 0 }}
                >{value}
                </div>
                <div className="col-4" style={{ flexDirection: 'column', display: 'flex', padding: '25px, 0' }}>
                    <div style={{ padding: 0, margin: 0 }} >
                        <ArrowDropUpIcon sx={{ padding: 0, cursor: 'pointer', fontSize: 'large' }}
                            onClick={(e) => arrowClick(e, 'up', rowVal)} />
                    </div>
                    <div style={{ padding: 0, margin: 0 }} >
                        <ArrowDropDownIcon sx={{ padding: 0, cursor: 'pointer', fontSize: 'large' }}
                            onClick={(e) => arrowClick(e, 'down', rowVal)} />
                    </div>
                </div>
            </div>
        )
    }
    const setData = () => {
        setRow(data.map(e => {
            const itemIngredients = recipes.find(ing => ing.posMenuItemId === e.itemId);
            if (!itemIngredients) {
                return null; // or any other action to skip this iteration
            }
            if (itemIngredients.ingredients.length > 0) { console.log(itemIngredients) }

            const prodCost = itemIngredients["productionCost"] || 0.0;
            const wasteFactor = itemIngredients["wasteFactor"] || 0.0
            return {
                // id: e.itemId,
                id: e.posItemId,
                col1: e.itemName,
                col2: e.variant ? e.variant : 'None',
                col3: e.category,
                col4: prodCost,
                prodWasteCost: prodCost + (prodCost * wasteFactor),
                salePrice: e.salePrice,
                margin: e.salePrice > 0 ? (e.salePrice - prodCost) / e.salePrice * 100 : (e.salePrice - prodCost) / 1 * 100,
                invOnHand: itemIngredients['sellableInventory'] || 0,
                col5: itemIngredients ? itemIngredients["ingredients"].length : 0
            }
        }).filter(item => item !== null));

        setCol([
            {
                field: 'col1', headerName: 'Item Name', minWidth: '175',
                renderCell: (params) => (
                    <div className="grid-cell-wrap"
                        style={{
                            whiteSpace: "normal", wordWrap: "break-word",
                            overflow: "hidden", textOverflow: "ellipsis",
                            lineHeight: "1.2em", maxHeight: "2.4em"
                        }}>
                        <CustomFieldRender value={params.value} />
                    </div>
                )
            },
            {
                field: 'col2', headerName: 'Item Variation', minWidth: '150',
                renderCell: (params) => (
                    <div className="grid-cell-wrap"
                        style={{
                            whiteSpace: "normal", wordWrap: "break-word",
                            overflow: "hidden", textOverflow: "ellipsis",
                            lineHeight: "1.2em", maxHeight: "2.4em"
                        }}>
                        <CustomFieldRender value={params.value} />
                    </div>
                )
            },
            { field: 'col3', headerName: 'Cost Category', minWidth: '185' },
            {
                field: 'col4', headerName: 'Cost / Single Item', minWidth: '120', valueFormatter: ({ value }) =>
                    `$ ${value.toFixed(2)}`
            }, {
                field: 'prodWasteCost', headerName: 'Cost Waste Inc.', minWidth: '120', valueFormatter: ({ value }) =>
                    `$ ${value.toFixed(2)}`
            },
            {
                field: 'salePrice', headerName: "Sale Price", minWidth: '100', valueFormatter: ({ value }) =>
                    value ? `$ ${value.toFixed(2)}` : '$ ' + 0.0
            },
            {
                field: 'margin', headerName: "Margin", minWidth: '120', valueFormatter: ({ value }) => {
                    const displayVal = isNaN(value) ? '0.0%' : `${value.toFixed(2)}%`
                    return displayVal
                }
            },
            { field: 'invOnHand', headerName: "Sellable Inventory", minWidth: '50' },// , renderCell: (params) => <CustomCellRenderer value={params.value} rowVal={params.id} /> },
            {
                field: 'col5', headerName: 'Recipe Exists?', minWidth: '145', renderCell: (params) => {
                    const noRecipe = params.value === 0
                    let lbl;
                    if (noRecipe) {
                        lbl = "No Recipe"
                    } else { lbl = "Recipe" }
                    return <Chip icon={noRecipe ? <WarningIcon sx={{ position: 'relative', left: '4px' }} /> : <CheckIcon sx={{ position: 'relative', left: '4px' }} />} label={lbl} variant={"outlined"} color={noRecipe ? "error" : "success"} />;
                }
            }
        ]);
    }

    const CustomFieldRender = ({ value }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', color: "#3f3f3f", fontSize: "13px", fontWeight: "600" }}>
                {value}
            </div>
        )
    }

    const dateClicked = async (start, end) => {
        setDate1(start)
        setDate2(end)

        const { getData, error } = await GetData("api/orderinfo", reload,
            { "startDate": start, "endDate": end, "menuItem": selSingleIngredient }, null, null)


        if (error) { console.log(error) }
        else if (Object.keys(getData).length === 0) {
            setIngredientStat(getData)
            setEmptyData('empty')
            setLoad(false)
        }
        else {
            setIngredientStat(getData)
            setLoad(false)
        }
    }

    const handleOpenEdit = (bool) => {
        if (bool) {
            setOpenEdit(true)
        }
    }

    const deleteModifier = async (bool) => {
        setDeleteOpen(false)
        if (bool) {
            const { success: modifierDeletd, error: deleteErr } = await DeleteData(`api/delete-modifier`, { "itemIds": selIngrIdArr })

            if (modifierDeletd) {
                setSelSingleIngredient(false)
                setSelIngrIdArr(null)
                setIngredientsHeader(null)
                setSelCategory(false)
                setDeleteIcon(false)
                setOpenSnackbar({ color: "success", message: "Successfully deleted item!", open: true })
                refreshPage()
            }
        }
    }

    const actionOptions = (type) => {
        switch (type) {
            case "pos refresh":
                setPosOpen(true)
                break;

            case "waste_factor":
                setOpenWasteFactor(true)
                break;
            case "copy recipe":
                setCopyRecipeOpen(true)
                break;
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>

                {/* <UserLocation /> */}
                <div className="col-xl-2 col-lg-3 me-auto d-flex align-items-center">
                    <MenuActionsButton selection={actionOptions} itemsSelected={selIngrIdArr} />

                    <button style={{ marginTop: '0', display: "flex", alignItems: "center", height: '32px' }} className={selIngrIdArr ? "delete-bttn" : "delete-dis"}
                        disabled={!selIngrIdArr}
                        onClick={() => setDeleteOpen(true)}
                    >
                        <DeleteIcon sx={{ color: selIngrIdArr ? "#dd4f4f" : "lightgray", '&:hover': { color: selIngrIdArr ? "#dd4f4f" : "lightgray" }, cursor: "pointer" }} fontSize="small" onClick={() => setDeleteOpen(true)} />
                        <span style={{ paddingTop: '2px', paddingLeft: '5px' }}>DELETE</span>
                    </button>
                </div>

                <GridToolbarQuickFilter
                    variant="outlined"
                    size="small"
                    sx={{
                        border: 'solid #1fb6ff', borderWidth: '1px', borderRadius: '5px', width: '225px', padding: '0', height: '32px',
                        '&.MuiFormControl-root': {
                            margin: '0'
                        }, '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'transparent', // Removes the default border color
                            },
                            '&:hover fieldset': {
                                borderColor: 'transparent',
                                backgroundColor: "#1976d20a" // Keeps the border color transparent on hover
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'transparent', // Ensures the border color remains the same when focused
                            },
                            '.MuiSvgIcon-root': { color: '#1fb6ff' },
                            '&.MuiInputBase-root input': { fontSize: 'small', color: '#1fb6ff', padding: '5px 0' }
                        }
                    }}
                />

                {isDummyLocation && <button className={!(selSingleIngredient === false || selSingleIngredient === null) ? "recipe-bttn" : "recipe-dis"}
                    disabled={(selSingleIngredient === false || selSingleIngredient === null)}
                    style={{ height: '32px', display: "flex", alignItems: 'center' }}
                    onClick={() => setOpenAddItem(true)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AddOutlinedIcon fontSize='medium' sx={{ color: !(selSingleIngredient === false || selSingleIngredient === null) ? "#1fb6ff" : "lightgray", marginRight: '5px', '&:hover': { color: "#14a9f3" } }} />
                        <span style={{ paddingTop: '2px' }}>ADD RECIPE</span>
                    </div>
                </button>}
            </GridToolbarContainer>
        );
    }

    const handlePOSChoice = async (bool) => {

        setPosOpen(false)
        if (bool) {
            setPosLoad(true)

            const { getData } = await GetData("api/updatemenu", null, null, null, null)
            if (getData.length > 0) {
                setPosLoad(false)
                setNoRecipeItem(curr => ({ ...curr, ["itemNames"]: getData, ["openDialog"]: true }))
            } else {
                setPosLoad(false)
                setOpenSnackbar({ color: "success", message: "Menu Updated Successfully!", open: true })
                refreshPage()
            }

        } else {
            setPosOpen(false)
        }
    }

    const selectClick = (e) => {

        if (e.length === 1) {
            // const findData = data.find((element) => e[0] === element.itemId)
            const findData = data.find((element) => e[0] === element.posItemId)

            setSelSingleIngredient(e[0])
            setSelIngrIdArr(e)
            setSelCategory(findData.category)
            setIngredientsHeader(findData.itemName)
            setDeleteIcon(true)
            if (date1 && date2) {
                dateClicked(date1, date2)
            }
        } else if (e.length > 1) {
            setSelSingleIngredient(false)
            setSelIngrIdArr(e)
            setIngredientsHeader(null)
            setSelCategory(false)
        } else {
            setSelSingleIngredient(false)
            setSelIngrIdArr(null)
            setIngredientsHeader(null)
            setSelCategory(false)
            setDeleteIcon(false)
        }
    }

    if (load) {
        return (<div style={{ position: 'relative', top: '5em', left: "45%" }}><CircularProgress /></div>)
    }
    return (
        <>
            <Dialog fullScreen open={openAddItem} onClose={handlePopupClose}>
                <AddItem ingredientId={selSingleIngredient} itemCategory={selCategory} close={handlePopupClose} />
            </Dialog >

            <Dialog open={openEdit} onClose={handlePopupClose} fullScreen>
                <EditMenuIngredients close={handlePopupClose} ingredientId={selSingleIngredient} itemCategory={selCategory} />
            </Dialog >

            {deleteOpen && <ConfirmDelete deleteItem={deleteModifier} deleteOpen={deleteOpen} />}
            {posOpen && <RefreshPOSMenu runApi={handlePOSChoice} posOpen={posOpen} />}

            <Dialog open={openWasteFactor} onClose={() => setOpenWasteFactor(false)} maxWidth={'sm'} sx={{ '.MuiPaper-root': { width: '450px' } }}>
                <WasteFactorConf posOpen={(val) => setOpenWasteFactor(val)} />
            </Dialog >

            <Dialog open={noRecipeItem.openDialog} onClose={handlePopupClose} maxWidth={'sm'} fullWidth>
                <NoRecipeItems close={handlePopupClose} menuItemsReturned={noRecipeItem?.itemNames} />
            </Dialog >

            <Dialog fullScreen open={copyRecipeOpen} onClose={handlePopupClose} >
                <CopyMenuItemRecipes close={handlePopupClose} selIngredients={selIngrIdArr} />
            </Dialog>

            <div className="row " style={{ width: "100%" }}>
                <div className="col-12 menu-list" >
                    <DataGrid
                        checkboxSelection
                        onRowSelectionModelChange={selectClick}
                        rows={row}
                        columns={col}
                        rowHeight={38}
                        slots={{
                            toolbar: CustomToolbar
                        }}
                        localeText={{
                            toolbarQuickFilterPlaceholder: 'SEARCH ITEMS'
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            padding: "10px 0 0 25px", border: "none", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '.MuiFormControl-root': { margin: '0 10px 5px 0' },
                            '.MuiDataGrid-main': { marginRight: "20px" },
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: "break-spaces",
                                lineHeight: "normal",
                                fontWeight: '600',
                                fontSize: 'small',
                                '@media (max-width:1080px)': { fontSize: '11px' }
                            },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px" },
                            '.MuiDataGrid-cellContent': { fontSize: "small" }
                        }}

                    />
                </div>
                <hr />
                <div className='row' style={{ width: "100%", margin: '10px 0 0 20px' }}>
                    <div className="col-xl-10 col-lg-9 col-md-9" >
                        <p style={{ margin: '0', fontWeight: "800" }}>{ingredientsHeader} Bill of Materials:</p>
                    </div>
                    {(selSingleIngredient !== false && selSingleIngredient !== null) &&
                        <div className='col-1' style={{ marginLeft: '75px' }}>
                            <button onClick={() => setOpenEdit(true)} className='blank-bttn' style={{ display: "flex", alignItems: 'center' }}>
                                <EditIcon fontSize='small' sx={{ marginRight: "5px" }} />
                                Edit
                            </button>
                        </div>
                    }
                </div>
                {/* ------------------------------------------------------------------------------------------------------------------------------------------------
                     The start of the 2 sections within the menu for the statistics : Ingredient & the Ordered stats
                ----------------------------------------------------------------------------------------------------------------------------------------------------*/}
                <div className="col-xl-12 col-lg-11 col-md-12 col-sm-12 " style={{ marginBottom: '75px' }}>

                    <div className="row mu-det" style={{ justifyContent: "space-around" }}>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ing-menu-mods" style={{ overflowY: 'auto', maxHeight: '350px' }}>

                            {(selSingleIngredient !== false && selSingleIngredient !== null) ?
                                <DisplayIngredients ingredientId={selSingleIngredient} editMenu={handleOpenEdit} refresh={reloadData} /> :
                                <>
                                    <strong
                                        style={{ margin: "10px 10px 0 0", fontWeight: "bold", fontSize: "large", height: "fit-content" }}>
                                        Product Recipe
                                    </strong>
                                    <p style={{ position: "relative" }}>select a product to display</p>
                                </>
                            }

                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ing-menu-mods" style={{ overflowY: 'auto', maxHeight: '350px', minHeight: selSingleIngredient ? '350px' : "auto" }}>

                            {(selSingleIngredient !== false && selSingleIngredient !== null) ?
                                <DisplayModifiers ingredientId={selSingleIngredient} editMenu={handleOpenEdit} refresh={reloadData} /> :
                                <>
                                    <strong
                                        style={{ margin: "10px 10px 0 0", fontWeight: "bold", fontSize: "large", height: "fit-content" }}>
                                        Product Modifier/Variant Materials
                                    </strong>
                                    <p style={{ position: "relative" }}>select a product to display</p>
                                </>
                            }

                        </div>
                    </div>

                </div>
                {openSnackbar.open &&
                    <SnackbarEx color={openSnackbar.color} message={openSnackbar.message} open={openSnackbar.open}
                        close={() => setOpenSnackbar({
                            color: "",
                            message: "",
                            open: false
                        })} />}

                {/* ------------------------ Menu POS load and success message ----------------------------*/}
                <Backdrop
                    sx={{ flexDirection: 'column', color: 'rgb(31, 182, 255)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={posLoad}
                >
                    <CircularProgress color="inherit" />
                    <p style={{ marginTop: "8px", color: "#fff" }}>Downloading menu...</p>

                </Backdrop>
            </div >

        </>
    )
}





