import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import useFetch from '../../services/useFetch.js'
import PostData from '../../services/PostData.js';
import { CircularProgress } from '@mui/material';

export default function CategorySettings({ close }) {
    /* 
        1. Pull any current categpries with a useFetch
        2. None ==> no initialized fields
        3. Allow users to edit / add new categories
        4. Add default categories? (Future?)  
    */
    const [categoryNames, setCategoryNames] = useState([])
    const [submitting, setSubmitting] = useState(false)

    const { data: curentCategories, error, load } = useFetch('api/menuCategories', false)

    useEffect(() => {
        setCategoryNames(curentCategories)
    }, [curentCategories])

    /* Add a new category row / text field */
    function onAdd(index) {
        setCategoryNames([...categoryNames, null])
    }
    /* Removing a category */
    const deleteRow = (index) => {
        const categories = [...categoryNames]
        const last = categories.length - 1

        if (index !== 0) {
            const indFrom = categories.splice(0, index)
            const indTo = categories.splice(index, last)
            const newList = indFrom.concat(indTo)

            setCategoryNames(newList)
        } else {
            setCategoryNames(categories.splice(1, last))
        }
    }

    const submitCategories = async () => {
        setSubmitting(true)
        const { success } = await PostData(categoryNames, "api/updateMenuCategories", null)
        if (success) {
            setSubmitting(false)
            close("success", "Categories")
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '60vh' }}>
            {/* Sticky Header */}
            <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, padding: '10px 0 0 0' }}>
                <div className='row' style={{ width: '100%', left: "5%", position: "relative", top: "10%" }}>
                    <div className='col-9'>
                        <h4 style={{ marginBottom: "10px" }}>Category Settings: </h4>
                    </div>
                    <div className='col-3' style={{ position: 'relative', left: "70px", marginBottom: "10px" }}>
                        <button type="button" className='blank-bttn'>
                            <CloseIcon onClick={() => close(false)} />
                        </button>
                    </div>
                </div>
                <hr style={{ marginBottom: "5px" }} />
            </div>
            <div className='row' style={{ margin: 0, height: '80vh', flexDirection: "column" }}>
                {
                    categoryNames?.map((elem, indx) =>
                        <div
                            className='row'
                            style={{ alignItems: 'end', padding: 0, margin: 0, height: 'min-content', marginBottom: 10 }}
                            key={indx}>
                            <div className='col-10' style={{ padding: 0, margin: '0 20px' }}>
                                <div className='row' style={{ margin: 0, padding: 0 }}>
                                    <label style={{ margin: 0, padding: 0 }}>
                                        Category Name {indx + 1}
                                    </label>
                                </div>
                                <div className='row' style={{ margin: 0, padding: 0, marginTop: 5 }}>
                                    <TextField
                                        size='small'
                                        variant="outlined"

                                        value={categoryNames[indx] || ''}
                                        onChange={(e) => {
                                            const updatedCategoryNames = [...categoryNames];
                                            updatedCategoryNames[indx] = e.target.value; // Update the specific index
                                            setCategoryNames(updatedCategoryNames)
                                        }
                                        }
                                        sx={{
                                            width: '100%', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" }, '@media (max-width:1080px)': { width: '72px' }
                                        }} >
                                    </TextField>
                                </div>
                            </div>
                            <div className='col-1' style={{ paddingRight: '0', paddingLeft: '25px', width: '5%' }}>
                                <DeleteIcon onClick={() => deleteRow(indx)} sx={{ cursor: 'pointer' }} fontSize='medium' />
                            </div>
                        </div>
                    )
                }
            </div>
            {/* ------------ ADD ROW BUTTON --------------- */}
            {/* <div className='row' style={{ margin: 0 }}>
                
            </div> */}

            {/* Sticky Footer */}
            <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', padding: '10px 0', borderTop: '1px solid #e0e0e0' }}>
                <div className='row' style={{ justifyContent: 'space-around', alignItems: 'center', margin: 0 }}>
                    <div className='col-6'>
                        <button id={"invoice-add-bttn"}
                            style={{ margin: '0 10px 0 0', left: 0 }}
                            onClick={onAdd}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AddOutlinedIcon fontSize='medium' sx={{ color: "#1fb6ff", marginRight: '5px', '&:hover': { color: "#14a9f3" } }} />
                                <span style={{ paddingTop: '2px' }}>ADD ITEM</span>
                            </div>
                        </button>
                    </div>
                    <div className='col-6' style={{ display: 'flex', justifyContent: 'end' }}>
                        <button
                            className='submit'
                            type='submit'
                            style={{ width: "80%", height: '36px', }}
                            onClick={submitCategories}>
                            {submitting ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Submit"}
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};
