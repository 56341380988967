import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PostPDF from '../../../services/PostPDF';
import CircularProgress from '@mui/material/CircularProgress';

export default function ProductCsv({ close }) {

    const [load, setLoad] = useState(false)
    const [uploadFile, setUploadFile] = useState(null)
    const [loadFileLookup, setLoadFileLookup] = useState(false)

    const handleFile = async ({ target }) => {
        const f = target.files[0]
        setUploadFile(f)
        await getHeaders(f)
    }
    const getHeaders = async (file) => {
        const apiUrl = 'api/upload-ingredients'
        const formData = new FormData()
        formData.append("file", file, file.name)
        const { data: headerData, unknowns: CSVUnknowns, error: CSVError } = await PostPDF(formData, apiUrl, 'head')
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        setLoad(true)

        try {
            const apiUrl = 'api/upload-ingredients'
            const formData = new FormData()
            formData.append("file", uploadFile, uploadFile.name)
            const CSVSuccess = false
            const { data: headerData, unknowns: CSVUnknowns, error: CSVError } = await PostPDF(formData, apiUrl, 'head')

            if (CSVError) {
                const errMessage = CSVError.response.data
                // setOpenTestSnack({ color: "error", message: errMessage, open: true })
                setLoad(false)
                // close('failure')
            }
            else if (CSVSuccess) {
                setLoad(false)
                close('success')
            }

        } catch (err) { console.log(err) }
        return
    }

    return (
        <div style={{ padding: '20px 24px', minWidth: '450px' }}>
            <div className='row' >
                <div className='col-10'>
                    <p style={{ fontSize: "20px" }}>Upload File:</p>
                </div>
                <div className='col-2'>
                    <CloseIcon fontSize='medium' onClick={() => close(true)} sx={{ cursor: 'pointer' }} />
                </div>

            </div>
            <hr style={{ marginTop: "0" }} />
            <div className='row'>
                <button style={{
                    background: "none", width: "150px", borderWidth: '.5px',
                    height: '35px', borderRadius: "5px", marginLeft: '12px', cursor: "pointer"
                }} >
                    <label htmlFor="file-upload" style={{ paddingTop: '2px', cursor: "pointer" }}>Choose file</label>
                    <input type="file" name="file"
                        onChange={handleFile} accept={'.csv'}
                        style={{ opacity: '0', right: '56%', position: 'absolute', width: '150px', cursor: 'pointer' }} />
                </button>
            </div>

            {/* {loadFileLookup &&
                <>
                    <CircularProgress sx={{ position: 'relative', left: '40%', marginTop: '15px' }} />
                    <p style={{ left: '35%', position: 'relative', width: 'max-content', marginTop: '8px', fontSize: '12px', color: 'gray' }}>Checking file...</p>
                </>
            } */}
            {uploadFile &&
                <div className='row' style={{ marginTop: '15px' }}>
                    <p style={{ margin: '0 0 0 10px' }}>File:<em> {uploadFile.name}</em></p>
                </div>
            }
            <div style={{ marginTop: '30px' }}>
                <button className='submit'
                    style={{ width: "100%", margin: 0 }}
                    onClick={handleUpload}>Upload</button>
            </div>
        </div>
    );
};
