import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import PostData from '../../../services/PostData';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export default function WasteFactorConf({ posOpen }) {
    /* Will setup the global waste factor to apply to all recipe calculations -- will add this % to all recipes */
    const handleClose = () => { posOpen(false) }
    const [wasteFact, setWasteFact] = useState(0)
    const [submitting, setSubmitting] = useState({ load: false, valid: false })


    const handleSubmit = async () => {
        setSubmitting((curr) => ({ ...curr, load: true }))
        const { success, error } = await PostData({ 'wasteFactor': wasteFact }, 'api/recipe/waste-factor', false)
        if (success) {
            setSubmitting((prevState) => ({ ...prevState, load: false, valid: true }))
            setTimeout(() => {
                posOpen(false)
            }, 500)

        }
    }

    return (
        <div style={{ padding: '10px', width: '500px' }}>
            <div className='row' >
                <div className='col-10' style={{ paddingLeft: '25px', width: '400px' }}>
                    <p style={{ marginBottom: "5px" }}>Add your global Recipe Waste Factor:</p>
                </div>
                <div className='col-1' style={{ paddingRight: '15px', paddingBottom: '10px' }}>
                    <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', height: '20px', width: "20px" }} />
                </div>
            </div>
            <hr style={{ margin: '0' }} />
            <div className='row' style={{ marginTop: '20px', padding: '0 20px' }}>
                <div className='col-12 text-start'>
                    <TextField
                        size='small'
                        sx={{ width: '400px', paddingLeft: '0' }}
                        placeholder='0'
                        onChange={(e) => setWasteFact(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                    />
                </div>
            </div>
            <div className='row' style={{ padding: '15px 50px 5px 20px' }}>
                <div className='col-12 text-end'>
                    <button
                        className='submit'
                        onClick={handleSubmit}
                        style={{ width: "100px", height: "30px", margin: "0 10px 0 0" }}>
                        {submitting.load ? <CircularProgress size={20} sx={{ color: 'white', position: 'relative', top: '2px' }} />
                            :
                            submitting.valid ? <CheckOutlinedIcon /> : 'Submit'}
                    </button>
                </div>

            </div>
        </div >
    );
};
