import React, { useState, useEffect } from 'react'
import MobileHeader from '../components/Headers/MobileHeader';
import NonMobileHeader from '../components/Headers/NonMobileHeader';

export default function Header({ logout, usrName, locations, reload, usrRole }) {


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust the width as per your mobile breakpoint

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Add event listener on mount
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        isMobile ? <MobileHeader logout={logout} usrName={usrName} locations={locations} reload={reload} usrRole={usrRole} />
            :
            <NonMobileHeader logout={logout} usrName={usrName} locations={locations} reload={reload} usrRole={usrRole} />
    )
}