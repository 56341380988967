import React, { createContext, useState, useEffect, useContext } from "react"
import GetData from "../services/GetData";

const LocationContext = createContext();


export const LocationProvider = ({ children }) => {
    const [locationAccess, setLocationAccess] = useState([])
    const [isDummyLocation, setIsDummyLocation] = useState(null)
    const [selectedLocation, setSelectedLocation] = useState(() => {

        // Initialize with the value from localStorage if available, otherwise default to null
        const storedLocation = localStorage.getItem('usrlctn');
        return storedLocation !== null ? storedLocation : null;
    });

    useEffect(() => {
        // Save selected location to sessionStorage whenever it changes
        localStorage.setItem('usrlctn', selectedLocation);
        selectedLocation && getLocationType()
    }, [selectedLocation]);

    const getLocationType = async () => {
        const { getData, error, load } = await GetData('api/business-isdummy')
        setIsDummyLocation(getData)
    }

    return (
        <LocationContext.Provider
            value={{ selectedLocation, setSelectedLocation, locationAccess, setLocationAccess, isDummyLocation }}>
            {children}
        </LocationContext.Provider>
    );
};

export const useLocation = () => useContext(LocationContext);
export { LocationContext }
// export const locationContext = React.createContext(null);
