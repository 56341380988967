import axios from "axios"

axios.defaults.withCredentials = true
const baseUrl = process.env.REACT_APP_NETLIFY_API_URL || process.env.REACT_APP_API_URL

export default async function PostPDF(data, url, type) {
    // const tkn = localStorage.getItem("urtkn")
    const tkn = sessionStorage.getItem("urtkn")
    const location = localStorage.getItem('usrlctn')
    try {

        const response = await axios.post(`${baseUrl}/${url}/${type}`, data,
            {
                "content-type": "multipart/form-data",
                headers: { Authorization: `Bearer ${tkn}` },
                params: { location: location }
            })

        if (response.status === 200) {
            return { data: response.data, error: false, load: false }
        } else { return { data: null, error: true, load: false } }

    } catch (err) {
        return { data: null, error: err, load: false }
    }
}