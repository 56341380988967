import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import useFetchWithParams from "../../services/useFetchWithParams.js"
import GetData from '../../services/GetData.js';
import MenuItem from '@mui/material/MenuItem';
import { recipeMeasurementConversions } from './UoMConversion.js';
import { UoMMenuOptions } from '../Helpers/UoMMenuOptions.js';

const apiUrl = "api/invoicedetail"

export default function DynamicEntry({ invoiceSum, updateBody, invoiceOrigin, invOriginVendor, selectedVendor }) {

    const [refresh, setRefresh] = useState(false)
    const [load, setLoad] = useState(true)
    const [fndItemUoM, setFoundItemUoM] = useState({});
    const [ingredientList, setIngredientList] = useState([])

    const [invoiceNum, setInvoiceNum] = useState(invoiceOrigin)
    const [invVendorId, setInvVendorId] = useState(invOriginVendor)

    const pack = ["Case", "Individual"]

    // const { data, error } = useFetch('api/ingredient-by-vendor', refresh)
    const { data: vendIngs, error: vendErr } = useFetchWithParams('api/ingredient-by-vendor', { "vendorId": selectedVendor }, refresh)

    useEffect(() => {
        if (invoiceOrigin && invOriginVendor) {
            fetchInvoice()
        }
    }, [])

    useEffect(() => {
        if (vendIngs) {
            setIngredientList([])
            vendIngs.map(ingr => {
                setIngredientList(prevIng => [...prevIng, ingr.ingredient])
            })
            setLoad(false)
        }
    }, [vendIngs])

    useEffect(() => {
        setRefresh(true)
        setTimeout(() => {
            setRefresh(false)
        }, 200)
    }, [selectedVendor])

    // ADD tsp <--> tbsp, Fl oz, etc

    const recipeUoM = ["oz", "floz", "tsp", "tbsp", "mL", "grams", "cup", "units", "none"]


    const [rowData, setRowData] = useState([
        {
            ingredient: "",
            vendorItemId: null,
            ingredientId: null,
            category: null,
            recipeUoM: "",
            itemUoM: "",
            itemSize: null,
            pack: null,
            packQuantity: null,
            packUnitQuantity: null,
            recipeIngQuantity: null, //Will get calculated in another function using the itemUoM, recipeUoM, and itemSize            
            quantity: null,
            total: 0.0
        }
    ]);

    const [addedCosts, setAddedCosts] = useState({
        discount: 0.0,
        tax: 0.0,
        shipping: 0.0,
    })
    /* If it's a repeat invoice -- we want to grab the original invoice and populate the rowData with it. */
    const fetchInvoice = async () => {

        const { getData, error } = await GetData(apiUrl,
            { "invnum": invoiceOrigin, "vendorId": invOriginVendor }, null, null, null)

        if (getData) {

            setLoad(false)
            setRowData(getData.ingredients)
            getData.ingredients.forEach((row, index) => {
                findItemUoM(row.recipeUoM, index);
            });
            setTimeout(() => {
                setInvoiceNum(false)
                setInvVendorId(false)
            }, 100)
        }

        if (error) { console.log(error) }
    }

    /* ---------------------------------------------------------------------------------------------------------------------
    Will grab and set the ingredient based on the ingredient chosen or input by the user 
    ---------------------------------------------------------------------------------------------------------------------*/
    const handleIngChange = async (e, val, reason, index, rowKey) => {

        if (reason !== 'clear') {
            const newIngredient = [...rowData]
            const namePart = val.split(" // ")[0]
            newIngredient[index][rowKey] = namePart
            setRowData(newIngredient)
            updateBody(newIngredient, index)

            if (vendIngs) {
                let nmIndx
                if (invoiceNum && invVendorId) {
                    nmIndx = vendIngs.findIndex(item => item.ingredient + item.vendorId === val + invOriginVendor)
                } else {
                    nmIndx = vendIngs.findIndex(item => item.ingredient === val)
                }
                if (nmIndx >= 0) {
                    const newData = [...rowData]
                    newData[index]['recipeUoM'] = vendIngs[nmIndx].recipeUoM

                    findItemUoM(vendIngs[nmIndx].recipeUoM, index)
                    newData[index]['category'] = vendIngs[nmIndx].category
                    newData[index]['itemUoM'] = vendIngs[nmIndx].parUoM
                    newData[index]['itemSize'] = vendIngs[nmIndx].singleItemSize
                    const recipeUnitQuant = await recipeMeasurementConversions(rowData[index]["recipeUoM"], rowData[index]["itemUoM"], vendIngs[nmIndx].singleItemSize)

                    newData[index]["recipeIngQuantity"] = Number(recipeUnitQuant)
                    newData[index]["quantity"] = recipeUnitQuant
                    newData[index]['ingredientId'] = vendIngs[nmIndx].ingredientId

                    setRowData(newData)
                    updateBody(newData, index)
                }
            }
        }
    }

    /* ---------------------------------------------------------------------------------------------------------------------
        Will read the input of the user and update the proper key:value pair in the Data const
        ---------------------------------------------------------------------------------------------------------------------*/
    const handleInputChange = async (index, { target }, rowKey) => {
        const uin = target.value
        const newData = [...rowData]
        newData[index][rowKey] = uin

        switch (rowKey) {

            case "pack":

                if (uin === "Individual") {
                    newData[index]["packUnitQuantity"] = 1.0
                    setRowData(newData)
                } else {
                    setRowData(newData)
                }
                break;

            case "packQuantity":
                const packQuant = [...rowData]
                if (rowData[index]["packUnitQuantity"] === 0.0) {

                    const calcQuant = Number(rowData[index]["packUnitQuantity"] * uin)

                    newData[index]["quantity"] = calcQuant * newData[index]["recipeIngQuantity"]

                    packQuant[index][rowKey] = Number(uin)
                    setRowData(newData, packQuant)
                } else {

                    const calcQuant = Number(rowData[index]["packUnitQuantity"] * uin)

                    newData[index]["quantity"] = calcQuant * newData[index]["recipeIngQuantity"]
                    packQuant[index][rowKey] = Number(uin)
                    setRowData(newData, packQuant)

                }
                break;

            case "packUnitQuantity":
                const updatedUQuant = [...rowData]
                if (rowData[index]["packUnitQuantity"] === 0.0) {

                    const calcQuant = Number(rowData[index]["packQuantity"] * uin)
                    // await recipeMeasurementConversions(rowData[index]["recipeUoM"], rowData[index]["itemUoM"], vendIngs[nmIndx].singleItemSize)                    
                    newData[index]["quantity"] = calcQuant * newData[index]["recipeIngQuantity"]

                    updatedUQuant[index][rowKey] = Number(uin)
                    setRowData(newData, updatedUQuant)
                } else {
                    const calcQuant = Number(rowData[index]["packQuantity"] * uin)

                    if (!newData[index]["recipeIngQuantity"]) {
                        const recipeUnitQuant = await recipeMeasurementConversions(rowData[index]["recipeUoM"], rowData[index]["itemUoM"], vendIngs[index].singleItemSize)
                        newData[index]["recipeIngQuantity"] = Number(recipeUnitQuant)
                        newData[index]["quantity"] = calcQuant * recipeUnitQuant
                    } else {
                        newData[index]["quantity"] = calcQuant * newData[index]["recipeIngQuantity"]
                    }
                    updatedUQuant[index][rowKey] = Number(uin)
                    setRowData(newData, updatedUQuant)
                }
                break;

            case "recipeUoM":
                findItemUoM(uin, index)
                if (uin === "none") {
                    newData[index] = {
                        ...newData[index],
                        "itemUoM": "none",
                        "itemSize": 1.0,
                        "pack": "Individual",
                        "packUnitQuantity": 1.0,
                        "packQuantity": 1.0,
                        "recipeIngQuantity": 1.0,
                        "quantity": 1.0
                    };

                    setRowData(newData)
                } else {
                    setRowData(newData)
                }

                break;

            case "itemUoM":
            case "vendorItemId":
                setRowData(newData)
                updateBody(newData, index)
                break;

            case "itemSize":
                const updatedRQuant = [...rowData]
                const updatedItemQuant = [...rowData]

                const recipeUnitQuant = await recipeMeasurementConversions(rowData[index]["recipeUoM"], rowData[index]["itemUoM"], Number(uin))
                updatedRQuant[index]["recipeIngQuantity"] = Number(recipeUnitQuant)

                updatedItemQuant[index]["quantity"] = recipeUnitQuant
                newData[index][rowKey] = uin
                setRowData(Number(newData), updatedRQuant, updatedItemQuant)

            default:
                newData[index][rowKey] = Number(uin)
                setRowData(newData)

        }
    }

    function findItemUoM(key, rowIndex) {

        const obj = UoMMenuOptions(key)
        if (obj) {
            const val = obj[key];
            setFoundItemUoM(prevUoMs => ({
                ...prevUoMs,
                [rowIndex]: val
            }));
        } else {
            setFoundItemUoM(prevUoMs => ({
                ...prevUoMs,
                [rowIndex]: [] // or some default/fallback value
            }));
        }
    }

    const handleExtraCosts = ({ target }, rowKey) => {
        if (target.value === "") {
            setAddedCosts(cur => ({ ...cur, [rowKey]: "" }))
            updateBody("", rowKey, null, "addedCost")
        } else {
            setAddedCosts(cur => ({ ...cur, [rowKey]: Number(target.value) }))
            updateBody(Number(target.value), rowKey, null, "addedCost")
        }
    };

    const totalSum = () => {
        const tot = rowData.reduce((total, row) => parseFloat(total) + (parseFloat(row.total) || parseFloat(0)), 0);
        const newSumTotal = Number(tot + parseFloat(addedCosts?.shipping) + parseFloat(addedCosts?.tax) - (parseFloat(addedCosts?.discount)))

        return newSumTotal
    }

    useEffect(() => {
        const newSum = totalSum()
        if (newSum !== invoiceSum) {
            invoiceSum(Number(newSum))
        }
    }, [rowData, invoiceSum]);

    /* ---------------------------------------------------------------------------------------------------------------------
      This will add a new clothing + category dropdown on click of an add button
    ---------------------------------------------------------------------------------------------------------------------*/
    function onAdd() {
        setRowData([...rowData, {
            ingredient: "",
            vendorItemId: null,
            ingredientId: null,
            recipeUoM: "",
            itemUoM: "",
            itemSize: null,
            pack: "",
            packQuantity: null,
            packUnitQuantity: null,
            recipeIngQuantity: null, //Will get calculated in another function using the itemUoM, recipeUoM, and itemSize            
            quantity: null,
            total: null
        }])

    }

    /* ---------------------------------------------------------------------------------------------------------------------
        This will delete the most recently added item to the Menu Item
    ---------------------------------------------------------------------------------------------------------------------*/
    const deleteRow = (index) => {
        const invoiceList = [...rowData]
        const last = rowData.length - 1

        if (index !== 0) {
            const indFrom = invoiceList.splice(0, index)
            const indTo = invoiceList.splice(index, last)
            const newList = indFrom.concat(indTo)

            setRowData(newList)
            updateBody(newList, null, true)
        } else {
            setRowData(invoiceList.splice(1, last))
            updateBody(invoiceList.splice(1, last), null, true)
        }

    }

    if (load) {
        return (<div className="container"><CircularProgress /></div>)
    }
    return (
        <>
            <div className='row' style={{ fontSize: 'small', marginTop: '10px' }}>
                <div className='col-2'>Item Name</div>
                <div className='col-1' style={{ marginRight: "10px" }}>Item ID</div>
                <div className='col-1' style={{ marginRight: "10px" }}>Recipe UoM</div>
                <div className='col-1' style={{ marginRight: "20px" }}>Item UoM</div>
                <div className='col-1 no-select-inp'>Item Size</div>
                <div className='col-1' style={{ marginRight: "10px" }}>Pack</div>
                <div className='col-1 no-select-inp'>Num Packs</div>
                <div className='col-1 no-select-inp'>Item / Pack</div>

                <div className='col-2'>Total Price</div>
            </div>

            <hr />
            <div className='row' style={{ overflowY: 'scroll', overflowX: 'hidden', height: "260px" }}>
                <div className='col-12'>
                    {
                        rowData.map((elem, indx) =>
                            <div className='row ' key={indx}>
                                <div className='col-2'>
                                    <Autocomplete
                                        freeSolo
                                        onInputChange={(e, val, reason) => handleIngChange(e, val, reason, indx, 'ingredient')}
                                        options={vendIngs ? ingredientList : []}
                                        value={rowData[indx]["ingredient"] || ''}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Item"
                                                value={rowData[indx]["ingredient"] || ''}
                                                variant='standard'
                                                sx={{ '& .MuiAutocomplete-inputRoot': { padding: '0' }, '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small", paddingRight: '0' } }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className='col-1' style={{ marginRight: "10px" }}>
                                    <TextField className="inv-hd" variant="standard"
                                        disabled={!rowData[indx]["ingredient"]}
                                        value={rowData[indx]["vendorItemId"] || ''}
                                        onChange={(e) => handleInputChange(indx, e, 'vendorItemId')}
                                        sx={{
                                            width: '85px', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" }, '@media (max-width:1080px)': { width: '72px' }
                                        }} >
                                    </TextField>
                                </div>
                                {/* --------------- Unit of measurement -----*/}

                                < div className='col-1' style={{ marginRight: "10px" }}>
                                    <TextField className="inv-hd" variant="standard"
                                        disabled={!rowData[indx]["ingredient"]}
                                        select
                                        placeholder='grams, units, oz, ml...'
                                        value={rowData[indx]["recipeUoM"] || ''}
                                        onChange={(e) => handleInputChange(indx, e, 'recipeUoM')}
                                        sx={{ width: '85px', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" }, '@media (max-width:1080px)': { width: '72px' } }}>
                                        {recipeUoM?.map((option, ind) =>
                                            <MenuItem key={ind} value={option}>
                                                {option}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </div>


                                {/* Category */}
                                <div className='col-1' style={{ marginRight: "20px" }}>
                                    <TextField
                                        disabled={!rowData[indx]["recipeUoM"]}
                                        select
                                        className="inv-hd"
                                        variant="standard"
                                        value={rowData[indx]["itemUoM"] || ''}
                                        onChange={(e) => handleInputChange(indx, e, 'itemUoM')}
                                        placeholder='Drinks, Food ...'
                                        sx={{ width: '85px', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} >
                                        {fndItemUoM[indx]?.map((option, ind) => (
                                            <MenuItem key={ind} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                {/* Unit Price */}
                                <div className='col-1 no-select-inp'>
                                    <TextField className="inv-hd" variant="standard"
                                        disabled={!rowData[indx]["itemUoM"]}
                                        value={rowData[indx]["itemSize"] || ''}
                                        onChange={(e) => handleInputChange(indx, e, 'itemSize')}
                                        type='number'
                                        InputProps={{
                                            endAdornment: rowData[indx]["itemUoM"] && (<InputAdornment position="end"> {rowData[indx]["itemUoM"]} </InputAdornment>),
                                        }}
                                        sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                                </div>

                                {/* Pack */}
                                <div className='col-1' style={{ marginRight: "10px", padding: '0' }}>
                                    <TextField
                                        select
                                        disabled={!rowData[indx]["itemSize"]}
                                        className="inv-hd" variant="standard"
                                        onChange={(e) => handleInputChange(indx, e, 'pack')}
                                        type='text'
                                        value={rowData[indx]["pack"] || ''}
                                        sx={{ width: '85px', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} >
                                        {pack?.map((option, ind) =>
                                            <MenuItem key={ind} value={option}>
                                                {option}
                                            </MenuItem>
                                        )}
                                    </TextField>
                                </div>

                                {/* Qty */}
                                <div className='col-1 no-select-inp'>
                                    <TextField className="inv-hd" variant="standard"
                                        disabled={!rowData[indx]["pack"]}
                                        value={rowData[indx].packQuantity || ""}
                                        onChange={(e) => handleInputChange(indx, e, 'packQuantity')}
                                        type='number'
                                        sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                                </div>
                                {/* Unit Qty */}
                                <div className='col-1 no-select-inp'>
                                    {rowData[indx].pack === "Case" ?
                                        <TextField className="inv-hd" variant="standard"
                                            disabled={!rowData[indx]["packQuantity"]}
                                            onChange={(e) => handleInputChange(indx, e, 'packUnitQuantity')}
                                            value={rowData[indx].packUnitQuantity || ""}
                                            type='number'
                                            sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                                        :
                                        <TextField className="inv-hd" variant="standard"
                                            disabled
                                            value={rowData[indx].packUnitQuantity || ""}
                                            type='number'
                                            sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                                    }
                                </div>

                                {/* Total Price */}

                                <div className='col-1' style={{ paddingRight: '5px', width: '12.5%' }}>
                                    <TextField
                                        className="inv-hd"
                                        variant="standard"
                                        type='number'
                                        value={rowData[indx]?.total ?? ""}
                                        onChange={(e) => handleInputChange(indx, e, 'total')}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        sx={{ '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                                </div>
                                <div className='col-1' style={{ paddingRight: '0', paddingLeft: '25px', width: '5%' }}>
                                    <DeleteIcon onClick={() => deleteRow(indx)} sx={{ cursor: 'pointer' }} fontSize='medium' />
                                </div>
                            </div>
                        )
                    }

                    {/* ------------ ADD ROW BUTTON --------------- */}

                    <button id={"invoice-add-bttn"}
                        style={{ marginRight: '10px' }}
                        onClick={onAdd}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <AddOutlinedIcon fontSize='medium' sx={{ color: "#1fb6ff", marginRight: '5px', '&:hover': { color: "#14a9f3" } }} />
                            <span style={{ paddingTop: '2px' }}>ADD ITEM</span>
                        </div>
                    </button>
                </div >
            </div >

            <div className='row' >
                <div className='col-12'>
                    <div className='row' id="inv-tot" style={{ marginRight: '0' }}>
                        <div className='col-xl-1 col-lg-2 col-md-2 col-sm-2'>
                            <p style={{ fontSize: 'small', margin: '0 0 0 40px' }}>Discount: </p>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-4' style={{ marginLeft: '35px' }}>
                            <TextField
                                size='small'
                                variant="standard"
                                type='number'
                                value={addedCosts?.discount ?? ""}
                                onChange={(e) => handleExtraCosts(e, 'discount')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                sx={{ width: '100px', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row' >
                <div className='col-12'>
                    <div className='row' id="inv-tot" style={{ marginRight: '0' }}>
                        <div className='col-xl-1 col-lg-2 col-md-2 col-sm-2'>
                            <p style={{ fontSize: 'small', margin: '0 0 0 40px' }}>Shipping: </p>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-4' style={{ marginLeft: '35px' }}>
                            <TextField
                                size='small'
                                variant="standard"
                                type='number'
                                value={addedCosts?.shipping ?? ""}
                                onChange={(e) => handleExtraCosts(e, 'shipping')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                sx={{ width: '100px', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row' >
                <div className='col-12'>
                    <div className='row' id="inv-tot" style={{ marginRight: '0' }}>
                        <div className='col-xl-1 col-lg-2 col-md-2 col-sm-2'>
                            <p style={{ fontSize: 'small', margin: '0 0 0 40px' }}>Tax: </p>
                        </div>
                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-4'>
                            <TextField
                                variant="standard"
                                size='small'
                                type='number'
                                onChange={(e) => handleExtraCosts(e, 'tax')}
                                value={addedCosts?.tax ?? ""}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                sx={{ width: '100px', '.MuiInputLabel-root, .MuiInput-root': { fontSize: "small" } }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
