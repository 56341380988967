import React, { useState, useEffect } from 'react'
import useFetch from '../../../../services/useFetch.js'
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from "@mui/x-data-grid"
import EditIcon from '@mui/icons-material/Edit';

export default function DisplayModifiers({ ingredientId, editMenu, refresh }) {

    const [row, setRow] = useState([])
    const [col, setCol] = useState([])
    const [load, setLoad] = useState(true)
    const [refreshData, setRefreshData] = useState(false)

    const { data, error } = useFetch(`api/menuItemsRecipe/${ingredientId}`, refreshData)

    useEffect(() => {
        if (data === null) {
            setLoad(true)
        } else {

            setLoad(false)
            setData()
        }

    }, [data])

    useEffect(() => {
        if (refresh) {
            setRefreshData(true)
            setTimeout(() => {
                // Code to be executed after the delay
                setRefreshData(false)
            }, 200);
        }
    }, [refresh])

    const setData = () => {
        // Ensure data is defined and has the necessary structure
        if (!data || data.length === 0 || !data.modifiers) {
            setRow([]);
            setCol([
                { field: 'modifier', headerName: 'Modifier', flex: 1.3 },
                { field: 'col1', headerName: 'Item', flex: 2 },
                { field: 'col2', headerName: 'Amount', flex: 1 },
                { field: 'col3', headerName: 'Measurement', flex: 1 }
            ]);
            return;
        }

        // Process the modifiers and map to the required structure
        const result = data.modifiers.flatMap((e, ingInd) => {
            // Check if the modifier is deleted and has ingredients
            if (e.isDeleted || !e.ingredients || e.ingredients.length === 0) {
                return [];
            }

            // Filter and map ingredients to the desired structure
            return e.ingredients
                .filter(ingredient => ingredient && ingredient.name)
                .map((ingr, ind) => ({
                    id: `${ingInd}-${ind}`, // Use a unique string-based id
                    modifier: e.modifierName,
                    col1: ingr.name || 'None',
                    col2: ingr.units && ingr.units[0] ? ingr.units[0].amount : 0,
                    col3: ingr.units && ingr.units[0] ? ingr.units[0].measurement : 'N/A'
                }));
        });

        // Set the processed rows
        setRow(result);

        // Define and set the columns for the MUI data grid
        setCol([
            { field: 'modifier', headerName: 'Modifier', flex: 1.4 },
            { field: 'col1', headerName: 'Item', flex: 2 },
            { field: 'col2', headerName: 'Amount', flex: 1 },
            { field: 'col3', headerName: 'Measurement', flex: 1 }
        ]);
    };

    if (load) return (<div style={{ position: 'relative', left: '45%', top: '15%' }}><CircularProgress size={30} /></div>)

    return (
        <>
            {/* Header row with the menu item name */}
            <div className='row' style={{
                position: 'sticky', top: "0", zIndex: '1',
                backgroundColor: 'white', padding: "15px 0 0 10px"
            }}>
                <div className='col-10'>
                    <h4 style={{ marginBottom: "15px", fontSize: 'medium' }}>Modifier Ingredients:</h4>
                </div>
            </div>
            <div className='row'>
                <hr style={{ margin: " 0 0 5px 0" }} />
            </div>

            {/* Table with each row being the ingredient in the menu item */}
            <div className='row' >

                <div className="col-12" style={{ height: "auto" }} >
                    <DataGrid
                        rows={row}
                        columns={col}
                        rowHeight={35}
                        pageSize={10}
                        pageSizeOptions={[10, 25]}
                        slots={{
                            noRowsOverlay: !load ? () => <div
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginLeft: '30px' }}>Add ingredients to the modifiers</div> : () => <div>No rows</div>,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            height: '38.5vh',
                            padding: "10px 0 0 25px", border: "none", overflowY: "scroll",
                            '.MuiToolbar-root': { alignItems: "baseline" },
                            '.MuiSvgIcon-root': { margin: 0 },
                            '&.MuiDataGrid-main': { marginRight: "20px" },
                            '&.MuiDataGrid-root': { paddingLeft: "0" },
                            '.MuiDataGrid-columnHeaderTitle': { fontSize: 'small', fontWeight: '600', '@media (max-width:1080px)': { fontSize: '11px' } },
                            '.MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end', marginRight: "10px" },
                            '.MuiDataGrid-cellContent': { fontSize: "small" },
                            '.MuiDataGrid-virtualScroller': { minHeight: '50px' }
                        }}

                    />
                </div>
            </div>
        </>
    )


}