/* Full screen dialog:
 - Choose between sub-assembly or pos item 
 - Once selected --> show a checklist to complete before submitting to add to the inventory
 */
import React, { useState, useEffect } from 'react';
import useFetch from '../../../services/useFetch.js'
import GetData from '../../../services/GetData.js'
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SkuAddChecklist from './SkuAddChecklist.jsx';

export default function AddSku({ close }) {

    // Fetch all items -- pos + sub assembly
    const { data: menuItems, load: loadMenu } = useFetch("api/menuItems", false)
    const { data: customMenuData, load, error } = useFetch('api/custom-menu-item', false)
    const [allItems, setAllItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [itemIngs, setItemIngs] = useState(null)

    useEffect(() => {
        if (menuItems && customMenuData) {
            const mergedItems = [...menuItems, ...customMenuData];

            // Sort the merged array by itemName (alphabetically)
            const sortedItems = mergedItems.sort((a, b) => {
                if (a.itemName < b.itemName) return -1;
                if (a.itemName > b.itemName) return 1;
                return 0;
            });
            setAllItems(sortedItems);
        }
    }, [customMenuData, menuItems])


    const getItemRecipe = async (val) => {

        setSelectedItem(val)

        const { getData: recipeInfo } = await GetData(`api/menuItemsRecipe/${val.itemId}`, null, null, null, null, null)

        if (recipeInfo) {
            setItemIngs(recipeInfo['ingredients'])
        }
    }

    const selectItemDrop = (sel) => {
        setSelectedItem({ itemId: sel.itemId, itemName: sel.itemName, itemType: sel?.type ?? 'pos' })
    }

    const addSkuSubmit = async () => {
        const { itemId, type } = selectedItem
        const iType = type ?? 'pos'
        const { getData, error } = await GetData(`api/upload-invoice/${itemId}/${iType}/up`, null, null, null, null)
        if (getData) {
            close(true)
        }
    }

    return (
        <div style={{
            overflow: 'hidden'
        }}>

            <div className='row' style={{ width: "100%", padding: "20px 0 0 10px", margin: 0 }
            } >
                <div className='col-11' style={{ paddingLeft: '1%' }}>
                    <h4 style={{ marginBottom: "0" }}>Add SKU: </h4>
                </div>
                <IconButton
                    onClick={() => close(false)}
                    sx={{
                        position: "absolute",
                        heigh: '40px',
                        width: '40px',
                        top: "5px", // Position above the drawer
                        right: "10px", // Adjust alignment
                        backgroundColor: "white",
                        boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                        zIndex: 1301, // Ensure it floats above the drawer
                        "&:hover": {
                            backgroundColor: "#f0f0f0",
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </ div>
            <hr />

            {/* -------------------- Dialog Body -------------------- */}
            <div className='row' id="sku-draw" style={{ margin: 0, padding: '0', flexDirection: 'column' }}>
                <div className='row' style={{ margin: 0, padding: '0 10px', alignItems: 'center', marginBottom: '30px' }}>
                    <label style={{ padding: '0 0 5px 0' }}>Select an Item:</label>
                    <div className='col-10' style={{ padding: 0 }}>
                        <Autocomplete
                            options={allItems}
                            loading={allItems.length === 0}
                            key={allItems.length}
                            size='small'
                            onChange={(e, val) => val ? getItemRecipe(val) : setSelectedItem(null)}
                            getOptionLabel={(option) => {
                                if (!option) return ""; // Handle null/undefined
                                return typeof option === "string" ? option : (option.variant ? option.itemName + ' - ' + option.variant : option.itemName) || "";
                            }}
                            isOptionEqualToValue={(option, value) => {
                                // Compare options using their unique id
                                return option.posItemId === value.posItemId
                            }}
                            value={selectedItem?.itemName ? selectedItem : ""}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'black', // Change outline color
                                    },

                                    '&:hover fieldset': {
                                        borderColor: '#1fb6ff', // Change outline color on hover
                                    },
                                },
                            }}
                            renderInput={(params, ind) => <TextField {...params}
                                placeholder='Select a menu item'
                            />}
                        />
                    </div>
                    <div className='col-2'>
                        <button className='submit' style={{ width: '50px', height: '38px' }} onClick={() => getItemRecipe()}>
                            <SearchOutlinedIcon size={'small'} />
                        </button>
                    </div>
                </div>

                {itemIngs && <div className='row receipt'
                    style={{ margin: 0, padding: 0, overflowX: 'hidden', height: '65vh' }}>
                    <SkuAddChecklist recipeData={itemIngs} />
                </div>}
            </div>


            {/* ------------------------------ Sticky Footer Button ------------------------ */}

            <div className='row'
                style={{ padding: "0", justifyContent: 'end', position: "sticky", borderTop: 'solid lghtgray' }}>
                <button disabled={itemIngs && itemIngs?.length === 0} className='submit' type='submit'
                    style={{ width: "25%", margin: '10px 20px 0 0' }} onClick={addSkuSubmit}>
                    Submit
                </button>
            </div>
        </div>
    );
};
