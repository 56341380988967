import React, { useState } from 'react';
import ImportCSVFile from '../components/Inventory/HandleUploadInvoice/ImportCSVFile';
import SnackbarEx from '../components/Helpers/SnackbarEx';
import PostPDF from '../services/PostPDF';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

export default function AppAdministration() {
    const [vendorSel, setVendorSel] = useState({ "VendorName": null, "InvoiceNum": null })
    const [upFile, setUpFile] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [puppeteer, setPuppeteer] = useState(false)
    const [openTestSnack, setOpenTestSnack] = useState({
        color: "",
        message: "",
        open: false
    })

    const handleFile = ({ target }) => {
        const f = target.files[0]
        setUpFile(f)
    }

    const updateVendorSelect = (val, key) => {
        setVendorSel(prev => ({
            ...prev,
            [key]: val
        }))
    }

    const handleUpload = async (e) => {
        setSubmitting(true)
        e.preventDefault()

        try {
            const formData = new FormData()
            formData.append("file", upFile, upFile.name)

            if (vendorSel) {
                const { success: CSVSuccess, unknowns: CSVUnknowns, error: CSVError } = await PostPDF(formData, "api/upload-invoice", vendorSel)
                setSubmitting(false)
                if (CSVError) {
                    const errMessage = CSVError.response.data
                    setOpenTestSnack({ color: "error", message: errMessage, open: true })
                }
                else if (CSVSuccess) {
                    refreshPage()
                    setOpenTestSnack({ color: "success", message: "File added success!", open: true })
                }
            } else {
                setOpenTestSnack({ color: "error", message: "Please add a vendor and invoice number!", open: true })
            }
        } catch (err) { console.log(err) }
        return
    }

    const refreshPage = () => {
        setVendorSel({ "VendorName": null, "InvoiceNum": null })
        setUpFile(null)
    }

    const handleAmazonRedirect = async () => {
        setPuppeteer(true)
        const tkn = sessionStorage.getItem("urtkn")
        const response = await axios.post("http://127.0.0.1:4000/api/aws-seller", null, {
            headers: { Authorization: `Bearer ${tkn}` }
        })
    };

    if (puppeteer) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }
    return (
        <div>
            <div className='sticky-header'>
                <div className='row pg-hdr' style={{ width: "98%" }}>
                    <div className='col-xl-9 col-lg-8 col-md-8' /*style={{ paddingLeft: '30px' }}*/ >
                        <h2>App Administrator</h2>
                        <p style={{ marginBottom: '0' }}>For internal use only</p>
                    </div>
                </div>
                <hr style={{ margin: '0' }} />
            </div>

            <div style={{ marginTop: "5%", position: "relative", padding: "10px 20px", border: "solid 1px", width: "35%", left: "25%" }}>
                <ImportCSVFile vendorSel={(vend, key) => updateVendorSelect(vend, key)} />

                <div className='row'>
                    <button style={{
                        background: "none", width: "150px", borderWidth: '.5px',
                        height: '35px', borderRadius: "5px", marginLeft: '12px', cursor: "pointer"
                    }}>
                        <label htmlFor="file-upload" style={{ paddingTop: '2px', cursor: "pointer" }}>Choose file</label>
                        <input type="file" name="file"
                            onChange={handleFile} accept={'.csv, .xls .pdf, .jpg'}
                            style={{ opacity: '0', right: '56%', position: 'absolute', width: '150px' }} />
                    </button>
                </div>

                <div className='row'>
                    {upFile &&
                        <div className='row' style={{ marginTop: '15px' }}>
                            <p style={{ margin: '0 0 0 10px' }}>File:<em> {upFile.name}</em></p>
                        </div>
                    }

                    <div style={{ marginTop: '30px' }}>
                        <button className='submit'
                            style={{ width: "400px", margin: '0', cursor: "pointer" }}
                            onClick={handleUpload}>{!submitting ? "Upload" : <CircularProgress size={25} sx={{ color: "white" }} />}
                        </button>
                    </div>
                </div>
                {/* AMAZON SELLER API REDIRECT */}
                <div>
                    <button onClick={handleAmazonRedirect}>Authorize with Amazon</button>
                </div>
            </div>
            {openTestSnack.open && <SnackbarEx color={openTestSnack.color} message={openTestSnack.message} open={openTestSnack.open}
                close={() => setOpenTestSnack({
                    color: "",
                    message: "",
                    open: false
                })} />}
        </div>
    );
};
