import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from '@mui/material';
import useFetch from '../../../services/useFetch.js'

export default function POSSetup() {
    // const posSystem = false
    // const load = false
    const { data: posSystem, error, load } = useFetch("api/business-pos-vendor", false)

    const appId = process.env.REACT_APP_NETLIFY_SQUARE_APP_ID || process.env.REACT_APP_SQUARE_APP_ID
    // const secrt = 'sandbox-sq0csb-65noNdotAcnDlACmZG1t9DOSNGvd2AilUeeCmZs8aBo'
    const sqUrl = process.env.REACT_APP_NETLIFY_SQUARE_CONNECT_URL || process.env.REACT_APP_SQUARE_CONNECT_URL
    const sqRedirect = process.env.REACT_APP_NETLIFY_SQUARE_REDIRECT || process.env.REACT_APP_SQUARE_REDIRECT


    const handleLogin = () => {
        const redirectUrl = encodeURIComponent(sqRedirect);
        window.location.href = `${sqUrl}/oauth2/authorize?client_id=${appId}&response_type=code&scope=MERCHANT_PROFILE_READ,ORDERS_READ,ITEMS_READ&state=unique-state&redirect_uri=${redirectUrl}`;
    };

    if (load) {
        return (<div className="container" style={{ left: '15%' }}><CircularProgress size={30} /></div>)
    }

    return (
        <div>
            <div className='row' style={{ padding: "5px 0 15px 15px" }}>
                <div className='col-12' style={{ padding: '0' }}>
                    <p style={{ fontSize: '20px', height: '30px', paddingLeft: '5px', margin: '0' }}>POS System: </p>
                    <hr style={{ borderWidth: '0.5px', margin: '0', width: '315px' }} />
                </div>

            </div >
            {posSystem ?
                <div className='row'>
                    <div className='col-12' style={{ paddingLeft: '15px' }}>
                        <TextField value={posSystem} size='small' disabled sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000"
                            },
                            "& .MuiFormHelperText-root.Mui-disabled": {
                                WebkitTextFillColor: "#000000"
                            }
                        }} />
                    </div>
                </div>
                :
                <div className='row'>
                    <p style={{ height: 'min-content', position: 'relative', top: '18%' }}>Have Sqaure POS?</p>
                    <button className='submit' onClick={handleLogin} style={{ width: '250px', position: 'relative', bottom: '25%' }}>Authorize with Square</button>
                </div>
            }
        </div>
    )
};

