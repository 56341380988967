import axios from "axios"
import qs from "qs"

const baseUrl = process.env.REACT_APP_NETLIFY_API_URL || process.env.REACT_APP_API_URL


export default async function GetData(url, data, filter, date1, date2) {
    // const tkn = localStorage.getItem("urtkn")
    const tkn = sessionStorage.getItem("urtkn")
    const location = localStorage.getItem('usrlctn')
    if (date1 && date2) {
        try {
            const paramData = {
                'location': location, [filter]: data,
                'startDateStr': date1, 'endDateStr': date2
            }

            const response = await axios.get(`${baseUrl}/${url}`, {
                headers: { Authorization: `Bearer ${tkn}` },
                params: paramData,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { indices: false });
                }
            })
            if (response.status === 200) {
                return { getData: response.data, error: false, load: false }
            } else { throw response }

        } catch (err) {
            return { data: null, error: err, load: false }
        }
    } else {

        try {
            const paramData = { ...data, "location": location }
            const response = await axios.get(`${baseUrl}/${url}`,
                { headers: { Authorization: `Bearer ${tkn}` }, params: paramData })
            if (response.status === 200) {
                return { getData: response.data, error: false, load: false }
            } else {
                throw response
            }

        } catch (err) {
            console.log(err)
            return { data: null, error: err, load: false }
        }
    }

}