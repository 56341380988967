import React, { useState } from 'react';
import useFetch from "../../../../services/useFetch.js"
import PostData from '../../../../services/PostData.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import AddIngredient from './AddIngredients.jsx';
import AddMenuItems from './AddMenuItems.jsx'
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';

export default function AddSpecialtyItem({ close }) {

    const [postLoad, setPostLoad] = useState(false)

    const { data: categories, load, error } = useFetch('api/menuCategories', false)

    // const UoM = ["oz", "floz", "tsp", "tbsp", "mL", "quarts", "grams", "cup", "liter", "units", "none"]
    const UoM = ["oz", "floz", "mL", "quarts", "grams", "cup", "liter", "units", "none"]
    const [menuItemName, setMenuItemName] = useState({
        name: null,
        category: "Beverages - Coffee",
        size: null,
        sizeUoM: "oz",
        type: "customIngredient",
        ageFactor: 0,
        ingredients: [{
            name: "",
            id: null,
            type: "custom",
            units: [{ amount: "", measurement: "" }]
        }],
        menuItems: []
    })
    const updateIngredients = (value) => {
        setMenuItemName({ ...menuItemName, ["ingredients"]: value })
    }

    const updateMenuItems = (value) => {
        setMenuItemName({ ...menuItemName, ["menuItems"]: value })
    }

    const handleSubmit = async () => {

        setPostLoad(true)

        const { success, error } = await PostData(menuItemName, 'api/custom-menu-item', false)
        if (success) {
            close("success")
            setPostLoad(false)
        }
    }

    if (load || postLoad) {
        return (<div className="container" style={{ top: "5px", left: "45%" }}><CircularProgress /></div>)
    }
    return (


        <div>
            <div className='row' style={{ width: '65', position: 'relative', left: '5.5%', marginTop: '25px' }} >
                <p style={{ fontSize: '20px', marginBottom: '5px' }}>Information</p>

                {/* -------------------------- Item Name ----------------------------------- */}
                <div className='row'>
                    <div className='col-xl-2 col-lg-2 col-md-3' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Item Name"}
                            sx={{
                                width: '100%',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0'
                                },
                                ".MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#2c2c2c"
                                },
                            }}
                            size='medium' />
                    </div>
                    <div className='col-xl-2 col-lg-3 col-md-3' style={{ maxWidth: "300px", paddingLeft: "0" }}>

                        <TextField
                            placeholder='Batch Brew Carafe, Homemade Syrup...'
                            onChange={(e) => setMenuItemName({ ...menuItemName, ["name"]: e.target.value })}
                            value={menuItemName["name"] || ""}
                            sx={{
                                width: '400px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    minWidth: '250px',
                                    borderRadius: '0'
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                }
                            }}
                            size='medium' />
                    </div>
                </div>

                {/* --------------------- Item Category ----------------------------------- */}
                <div className='row'>
                    <div className='col-xl-2 col-lg-2 col-md-3' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Category"}
                            sx={{
                                width: '100%',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0'
                                },
                                ".MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#2c2c2c"
                                },
                            }}
                            size='medium' />
                    </div>
                    <div className='col-xl-3 col-lg-3 col-md-2' style={{ maxWidth: "300px", paddingLeft: "0" }}>

                        <TextField
                            select

                            onChange={(e) => setMenuItemName({ ...menuItemName, ["category"]: e.target.value })}
                            value={menuItemName["category"]}

                            sx={{
                                width: '400px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    }
                                }
                            }}
                            size='medium' >
                            {categories?.map((option, ind) =>
                                <MenuItem key={ind} value={option}>
                                    {option}
                                </MenuItem>
                            )}
                        </TextField>
                    </div>
                </div>

                {/* --------------------------- Size ----------------------------------- */}
                <div className='row'>
                    <div className='col-xl-2 col-lg-2 col-md-3' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Size"}
                            sx={{
                                width: '100%',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0'
                                },
                                ".MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#2c2c2c",

                                },

                            }}
                            size='medium' />
                    </div>
                    <div className='col-xl-3 col-lg-3 col-md-2' style={{ maxWidth: "300px", paddingLeft: "0" }}>

                        <TextField
                            type='number'
                            placeholder='120'
                            onChange={(e) => setMenuItemName({ ...menuItemName, ["size"]: Number(e.target.value) })}
                            value={menuItemName["size"] || ""}
                            sx={{
                                width: '400px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                }
                            }}
                            size='medium' />
                    </div>
                </div>

                {/* --------------------- Item Size UoM ----------------------------------- */}
                <div className='row'>
                    <div className='col-xl-2 col-lg-2 col-md-3' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Item size UoM"}
                            sx={{
                                width: '100%',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0'
                                },
                                ".MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#2c2c2c"
                                },
                            }}
                            size='medium' />
                    </div>
                    <div className='col-xl-2 col-lg-3 col-md-2' style={{ maxWidth: "300px", paddingLeft: "0" }}>
                        {/* <Autocomplete

                            noOptionsText={"Loading..."}
                            size="medium"
                            options={UoM ?? []}

                            value={menuItemName["sizeUoM"] || ""}
                            onChange={(e, val) => {
                                console.log(val)
                                setMenuItemName({ ...menuItemName, sizeUoM: val })
                            }}
                            sx={{
                                width: '400px',
                                '.MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                />
                            )}>
                        </Autocomplete> */}
                        <TextField
                            select
                            onChange={(e) => {
                                setMenuItemName((prevState) => ({
                                    ...prevState,
                                    sizeUoM: e.target.value || "",
                                }))
                            }}
                            value={menuItemName["sizeUoM"] || ""}
                            sx={{
                                width: '400px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                }
                            }}
                            size='medium' >
                            {UoM?.map((option, ind) =>
                                <MenuItem key={ind} value={option}>
                                    {option}
                                </MenuItem>
                            )}
                        </TextField>
                    </div>
                </div>

                {/* --------------------- Waste Factor ----------------------------------- */}
                <div className='row'>
                    <div className='col-xl-2 col-lg-2 col-md-3' style={{ paddingRight: '0' }}>

                        <TextField
                            disabled
                            value={"Item Aging?"}
                            sx={{
                                width: '100%',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    backgroundColor: 'lightgray',
                                    borderRadius: '0'
                                },
                                ".MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#2c2c2c"
                                },
                            }}
                            size='medium' />
                    </div>
                    <div className='col-xl-2 col-lg-3 col-md-2' style={{ maxWidth: "300px", paddingLeft: "0" }}>

                        <TextField
                            type='number'
                            onChange={(e) => {
                                const value = e.target.value;
                                setMenuItemName({
                                    ...menuItemName,
                                    ["ageFactor"]: value === "" ? null : Number(value),
                                })
                            }}
                            value={menuItemName["ageFactor"] !== null ? menuItemName["ageFactor"] : ""}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>,
                            }}
                            sx={{
                                width: '400px',
                                '&.MuiTextField-root .MuiInputBase-root': {
                                    borderRadius: '0'
                                },
                                '&.MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray', // Default outline color
                                    },
                                }
                            }}
                            size='medium' >

                        </TextField>
                    </div>
                </div>
            </div>

            {/* --------------------- Add the Custom item's ingredients ----------------------------------- */}
            <div className='row' style={{ position: 'relative', left: '5.5%', marginTop: "28px" }}>
                <p style={{ fontSize: '16px', marginBottom: '5px' }}>Inventory Items</p>
                <AddIngredient updateIngredients={updateIngredients} />
            </div>

            {/* --------------------- Add the POS items that use the Custom item ----------------------------------- */}
            <div className='row' style={{ position: 'relative', left: '5.5%' }}>
                <p style={{ fontSize: '16px', marginBottom: '5px', marginTop: "25px" }}>POS Menu Item</p>
                <AddMenuItems updateMenuItems={updateMenuItems} itemType={"customIngredient"} customSize={menuItemName.sizeUoM} />
            </div>


            <div className='row' style={{ paddingBottom: "20px", paddingLeft: '5.5%', justifyContent: 'start', position: "relative", top: '23%' }}>
                <button className='submit' type='submit' style={{ width: "25%" }} onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </div >



    );
};
