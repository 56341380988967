import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function HoverOverTootlTip({ message, children, placement }) {

    return (
        <>
            <Tooltip title={message} placement={placement} arrow
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10],
                                },
                            },
                        ],
                    },
                }}
                sx={{ padding: 0, margin: 0, pointerEvents: 'auto' }}>
                {children}
            </Tooltip>

        </>
    );
};
